import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {DialogActions} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmDialog = ({open, setOpen, onConfirm, content, title, size, buttonConfirmContent}) => {
    const {t} = useTranslation();
    return (<Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={size ? size : "sm"}
        scroll="paper"
        fullWidth={true}>
        <DialogTitle>
            <div className="flex justify-between border-bottom pb-4">
                <div>{title ? title : t('notify')}</div>
                <div className="ce-tune-alignment--center">
                    <CloseIcon className="cursor-pointer" onClick={() => setOpen(false)}/>
                </div>
            </div>
        </DialogTitle>
        <DialogContent>
            <div className="px-[24px] pb-4">
                {content ? content : t('confirmMessage')}
            </div>

        </DialogContent>
        <DialogActions>
            <div className="px-[8px] pb-4">
                <button className="bg-[#F6F6F6] text-[#000000] border-[0px] w-[200px]"
                    onClick={() => setOpen(false)}>{t('cancel')}</button>
                <button className="bg-[#F0C528] text-[#000000] border-[0px] w-[200px]"
                    onClick={onConfirm}>{buttonConfirmContent ? buttonConfirmContent : t('confirm')}</button>
            </div>
        </DialogActions>
    </Dialog>)
}

export default ConfirmDialog;
