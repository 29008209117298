import React, {useState, useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";
import HomePage from "../HomePage/homePage";
import Note from '../NoteDetails/Note';
import CancelIcon from '@mui/icons-material/Cancel';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import {showToast} from '../common/Toast'
import api from "../axios";
import Attendance from "./Attendance";
import "./note.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ConfirmDialog from "../common/ConfirmDialog";
import {toast} from "react-toastify";

const NoteDetails = ({handleSignOut, isDialog, isView, viewUserId, onCloseDialog}) => {
    const location = useLocation();
    const isCompanyLearningHour = location?.state?.isCompanyLearningHour;
    const isCompensation = location?.state?.isCompensation;
    const compensationTime = location?.state?.compensationTime;
    const {t} = useTranslation()
    const storedAccessToken = localStorage.getItem('accessToken');
    const id = useParams()
    const [courseData, setCourseData] = useState({notes: []});
    const [isFinished, setIsFinished] = useState(false);
    const [user_attend, setUser_attend] = useState([]);
    const [isVisible, setIsVisible] = useState(true);
    const [isCancelled, setIsCancelled] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isCompletion, setIsCompletion] = useState(false)
    const [instructor, setInstructor] = useState("")
    const [courseType, setCourseType] = useState("")
    const [effect, setEffect] = useState(true);
    const [isJoined, setIsJoined] = useState(false);
    const [shortDescription, setShortDescription] = useState(true);
    const [openFinishDialog, setOpenFinishDialog] = useState(false);
    const [needViewMore, setNeedViewMore] = useState(true);

    const handleAddItem = newItem => {
        // Send a request to the API to add the new item
        api.post(`${process.env.REACT_APP_BASE_URL}/items/note`, newItem).then(response => {
            fetchCourseData();
        }).catch(error => {
            showToast(t(error.response.data.errors[0].message), "warning")
        })
    };

    const fetchCourseData = async () => {
        try {
            let url = isDialog
                ? `${process.env.REACT_APP_BASE_URL}/items/note?fields=*&filter[course_id][_eq]=${id.id}&filter[user_created][_eq]=${viewUserId}&sort=-date_created`
                : `${process.env.REACT_APP_BASE_URL}/flows/trigger/20202c51-f8a4-4204-a479-b0b40f064f90?id=${id.id}`
            const response = await fetch(url, {
                headers: {
                    Accept: "*/*", "Content-Type": "application/json", Authorization: `Bearer ${storedAccessToken}`
                }
            });
            const data = await response.json();
            if (data.data.isCompletion !== isCompletion) {
                setIsCompletion(data.data.isCompletion);
            }
            setCourseData(!isDialog ? data.data : {notes: data.data});
            if (isDialog) return;
            setUser_attend(data.data?.user_attend)
            setInstructor(data.data?.instructor)
            setCourseType(data.data?.course_type)
            setIsFinished(data.data?.is_finished)
            setIsJoined(data.data?.user_attend && data.data?.user_attend.filter(user => user.directus_users_id.id === userID).length > 0);
        } catch (error) {
            console.error("Error fetching course data:", error);
        }
    };

    if (effect) {
        setEffect(false);
        fetchCourseData();
    }

    useEffect(() => {
        const description = document.getElementById("description");
        if (!description) {
            setNeedViewMore(false)
        } else {
            const content = "<p>" + description.childNodes[0].innerHTML + "</p>";
            setNeedViewMore(content !== courseData.description);
        }
    }, [courseData, needViewMore])

    const handleDeleteItem = id => {
        // Update the UI first
        setCourseData(prevData => ({
            ...prevData,
            notes: prevData.notes.filter(item => item.id !== id),
        }));

        // Send the DELETE request to the server
        fetch(`${process.env.REACT_APP_BASE_URL}/items/note/${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete item');
                }
            })
            .catch(error => {
                console.error('Failed to delete item:', error);
                // If the request fails, revert the UI back to the original state
                setCourseData(prevData => ({
                    ...prevData,
                    notes: [...prevData.notes, {id}], // Add the deleted item back
                }));
            });
    };

    function formatString(str) {
        if (str == null) {
            return "";
        }
        return str.replace(/\n/g, '<br>');
    }

    const handleCancelClick = () => {
        setIsInfoVisible(!isInfoVisible)
        setIsCancelled(true);
        setIsVisible(false);
    };

    const timeLesson = (time) => {
        return time < 1 ? `${time * 60} ${t('minutes')}` : `${time} ${t('hours')}`;
    };

    const userID = localStorage.getItem("userID")

    const handleCompletedCourse = async () => {
        const completeCourse = !isCompletion;
        if (completeCourse) {
            if (courseData.notes.length === 0) {
                showToast(t("COURSE_HAS_NOT_BEEN_NOTED"), "error")
                return;
            }
        }
        const requestData = {
            course_id: id.id,
            is_completed: completeCourse
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/flows/trigger/3e5411f6-cf6d-4f85-926d-5d560ba39c2b`, requestData, {
                    headers: {
                        Accept: "*/*", "Content-Type": "application/json", Authorization: `Bearer ${storedAccessToken}`
                    }
                }
            );
            if (completeCourse && response.data.status && parseInt(response.data.status) !== 200) {
                showToast(t(response.data.errors[0].extensions.code), "error")
                return;
            }
            if (completeCourse) {
                showToast(t("completeCourseBtn"), "success")
            } else {
                showToast(t("continueCourseBtn"), "success")
            }
            await fetchCourseData();
        } catch (error) {
            console.error(error);
        }
    }

    const handleFinishedCourse = () => {
        if (isFinished) {
            return;
        }
        setOpenFinishDialog(true);
    }

    const confirmFinishCourse = async () => {
        const requestData = {
            course_id: id.id,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/course/finish-course`, requestData, {
                    headers: {
                        Accept: "*/*", "Content-Type": "application/json", Authorization: `Bearer ${storedAccessToken}`
                    }
                }
            );
            setIsCompletion(response.data.is_completed)
            showToast(t("finishCourseSuccess"), "success")
            setOpenFinishDialog(false)
            await fetchCourseData();
        } catch (error) {
            toast.error(error.error?.message);
            console.error(error);
        }
    }

    const checkInstructor = () => {
        return instructor?.id === userID;
    }

    const checkHR = () => {
        return localStorage.getItem("role") === 'HR';
    }

    const joinCourse = async () => {
        if (isJoined || courseData.is_finished) return;
        const idSend = id.id
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/items/course/${idSend}`;
        let bodyData = {
            "user_attend": {
                "create": [
                    {
                        "course_id": {idSend},
                        "directus_users_id": {
                            "id": userID,
                        }
                    }
                ],
                "update": []
            }
        };

        const requestOptions = {
            method: 'PATCH', // Use PATCH for this example
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedAccessToken}`,
            },
            body: JSON.stringify(bodyData)
        };

        try {
            await fetch(apiUrl, requestOptions);

            fetchCourseData();
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const isCourseType = courseType === "COURSE";
    const isAttendance = isCourseType && (checkInstructor() || checkHR());

    const reload = (isLoad) => {
        if (isLoad) {
            fetchCourseData();
        }
    }

    const NodeInformation = <div className={`flex ${isDialog ? '' : 'px-[5%] pt-10 pb-20'} ${isAttendance ? 'bg-[#FFFFFF]' : 'bg-[#F5F5F5]'}`}
                                style={{padding: isAttendance ? '20px' : '',
                                        borderRadius: isAttendance ? '16px' : ''}}>
        <div
            className={`w-full border-solid border ${isAttendance ? 'h-[57vh]' : 'h-[76.7vh]'} border-[#979696] rounded-2xl flex ${isCancelled ? "w-full" : "w-8/12"}`}>
            <Note courseData={courseData?.notes}
                  idNoted={id.id}
                  setIsCancelled={setIsCancelled}
                  setIsVisible={setIsVisible}
                  setIsInfoVisible={setIsInfoVisible}
                  isInfoVisible={isInfoVisible}
                  courseType={courseType}
                  isDialog={isDialog}
                  isView={isView}
                  onAddItem={handleAddItem}
                  onDeleteItem={handleDeleteItem}
                  onCloseDialog={onCloseDialog}
                  isAttendance={isAttendance}
                  isCompletion={isCompletion}
                  isCompanyLearningHour={isCompanyLearningHour}
                  isCompensation={isCompensation}
                  compensationTime={compensationTime}/>
        </div>
        {!isDialog && !isCancelled && !isAttendance && (<div className="pl-6 w-4/12">
            {isVisible && (<div
                className="rounded-2xl border-[#979696] border-solid border">
                <div className="px-6 h-[76.7vh] overflow-y-auto" id="hideScroll">
                    <div className="relative cursor-pointer">
                        <CancelIcon onClick={handleCancelClick}
                                    className="absolute right-0 top-0 mt-6"/>
                    </div>
                    {courseData && (<>
                        <div className="font-bold text-[32px] leading-[120%] text-left pt-12 cursor-default pb-2">
                            {courseData.title}
                        </div>
                        {isCompletion && <div className="flex justify-start py-2 border-top border-bottom border-[#D5D5D5]">
                            <div>
                                <span>{t('status')}</span>: <span className="font-bold text-[#34A853]">{t('completed')}</span>
                            </div>
                        </div>}
                        {isCourseType ? (
                            // This will be shown if isCourseType is true
                            <div className="flex-1 py-4 cursor-pointer">
                                <div
                                    className={`flex justify-center items-center px-1 py-2 text-center transition duration-300 ease-in-out transform border rounded-md shadow-md text-[#2F2E2E] 
                                            ${isJoined || courseData.is_finished ? '' : 'border-[#F0C528] hover:scale-105'}`}
                                    style={{backgroundColor: isJoined ? '#D5D5D5' : '#F0C528'}}
                                    onClick={joinCourse}
                                >
                                    <div className="whitespace-nowrap break-words pr-1">
                                        {isJoined ? t("joinedCourse") : t("joinCourse")}
                                    </div>
                                </div>
                                {instructor &&
                                <div className='m-b-2 pt-4'>
                                    <div className='flex'>
                                        <div>{t('instructor')}: </div>
                                        <div><strong>{instructor.last_name} {instructor.first_name}</strong></div>
                                    </div>
                                    <div className='flex'>
                                        <div>{t('time')}: </div>
                                        <div><strong>{timeLesson(courseData.lesson_time)}/{t('lesson')}</strong></div>
                                    </div>
                                </div>
                                }
                            </div>
                        ) : (
                            // This will be shown if isCourseType is false
                            <div className="flex flex-col sm:flex-row">
                                {courseData.link && <div className="flex-1 py-4 pr-2">
                                    <a
                                        className="flex justify-center items-center px-1 py-2 text-center transition duration-300 ease-in-out transform border border-[#F0C528] rounded-md shadow-md bg-[#F0C528] text-[#2F2E2E] hover:scale-105"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={courseData.link}
                                    >
                                        <span className="whitespace-nowrap break-words">
                                          {t("goToCourse")}
                                        </span>
                                    </a>
                                </div>
                                }
                                <div className="flex-1 py-4">
                                    <button
                                        className={`cursor-pointer flex justify-center w-full items-center px-1 py-2 text-center transition duration-300 ease-in-out transform border border-[#F0C528] rounded-md shadow-md text-[#2F2E2E] hover:scale-105
                                        ${isCompletion ? '' : 'bg-[#F0C528]'}`}
                                        onClick={handleCompletedCourse}
                                    >
                                        {isCompletion ? (
                                            <span className="whitespace-nowrap break-words">
                                                {t("continueCourse")}
                                            </span>
                                        ) : (
                                            <span className="whitespace-nowrap break-words">
                                                {t("completCourse")}
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="text-left cursor-default"
                             dangerouslySetInnerHTML={{__html: formatString(courseData.description)}}></div>
                    </>)}
                </div>
            </div>)}
        </div>)}
    </div>;

    return (<>
        {!isDialog &&
        <HomePage handleSignOut={handleSignOut}/>}

        {isDialog || !isAttendance ? (
            NodeInformation
            ) : (
                <div className='mx-[5%] flex flex-col' style={{height: 'calc(100vh - 160px'}}>
                    <div style={{flex: '0 1 auto'}}>
                        <div className="flex justify-between">
                            <div className="font-bold text-[32px] leading-[120%] text-left cursor-default pb-2">
                                {courseData.title}
                            </div>
                            <div>
                                {courseData.is_finished ?
                                    <button className={`bg-transparent font-semibold py-2 px-4 border border-[#F0C528] rounded mr-[8px] rounded opacity-50 cursor-not-allowed`}
                                            disabled={true}
                                            onClick={handleFinishedCourse}>{t("endedCourse")}</button>
                                :
                                    <button className={`bg-transparent font-semibold py-2 px-4 border border-[#F0C528] rounded mr-[8px] cursor-pointer hover:border-transparent hover:bg-yellow-500 hover:text-white`}
                                            disabled={false}
                                            onClick={handleFinishedCourse}>{t("endCourse")}</button>}
                                {!checkInstructor() && ((courseData.is_finished || isJoined) ?
                                    <button className={`bg-transparent font-semibold py-2 px-4 border border-[#F0C528] rounded mr-[8px] rounded opacity-50 cursor-not-allowed`}
                                            disabled={true}
                                            onClick={joinCourse}>{isJoined ? t("joinedCourse") : t("joinCourse")}</button>
                                :
                                    <button className={`bg-transparent font-semibold py-2 px-4 border border-[#F0C528] rounded cursor-pointer hover:border-transparent hover:bg-yellow-500 hover:text-white`}
                                            disabled={false}
                                            onClick={joinCourse}>{isJoined ? t("joinedCourse") : t("joinCourse")}</button>)}

                            </div>
                        </div>
                        <div className='m-b-2 pb-2 flex justify-start'>
                            <div>{t('instructor')}:</div>
                            <div><strong>{instructor.last_name} {instructor.first_name}</strong></div>
                        </div>
                        {courseData.description &&
                            (shortDescription ?
                                <div className='pb-4'>
                                    <div className={`text-left cursor-default pb-1 line-clamp-1`} id="description"
                                         dangerouslySetInnerHTML={{__html: formatString(courseData.description)}}/>
                                    {needViewMore && <div className='flex justify-start'>
                                        <span href='#' className='cursor-pointer underline hover:text-[#0000FF]' onClick={() => setShortDescription(false)}>{t('viewMore')}</span>
                                    </div>}
                                </div>
                            :   <div className='pb-4'>
                                    <div className="text-left cursor-default pb-1" id="description"
                                     dangerouslySetInnerHTML={{__html: formatString(courseData.description)}}/>
                                    <div className='flex justify-start'>
                                        <span className='cursor-pointer underline hover:text-[#0000FF]' onClick={() => setShortDescription(true)}>
                                            {t('viewLess')}
                                        </span>
                                    </div>
                                </div>)
                        }
                    </div>
                    <div style={{flex: '1 1 auto'}}>
                    <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab>
                                <div className="w-[160px] px-5%">{t('attendance')}</div>
                            </Tab>
                            {(checkInstructor() || isJoined) &&
                                <Tab>
                                    <div className="w-[160px] px-5%">{t('notes')}</div>
                                </Tab>
                            }
                        </TabList>

                        <TabPanel>
                            <div className={`shadow-md border shadow-md duration-300 bg-white h-full`}
                                 style={{borderRadius: '0 8px 8px 8px'}}>
                                <Attendance courseData={courseData}
                                            userAttendance={user_attend}
                                            isFinished={isFinished}
                                            onReload={reload}/>
                            </div>
                        </TabPanel>
                        {(checkInstructor() || isJoined) &&
                            <TabPanel>
                                <div className="shadow-md border shadow-md rounded-lg duration-300 bg-white">
                                    {NodeInformation}
                                </div>
                            </TabPanel>
                        }
                    </Tabs>
                    </div>
                </div>
            )}

        {openFinishDialog && <ConfirmDialog open={openFinishDialog} setOpen={setOpenFinishDialog}
                                            onConfirm={confirmFinishCourse}
                                            content={t('confirmFinishCourse')}
                                            buttonConfirmContent={t('finishCourse')}/>}
    </>);
};

export default NoteDetails;
