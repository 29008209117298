import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import api from "../axios";
import DialogContent from "@mui/material/DialogContent";
import "./note.css";
import Dialog from "@mui/material/Dialog";
import NoteDetails from "./noteDetails";
import {toast} from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const Attendance = ({
                        userAttendance, courseData, isFinished,
                        onReload
                    }) => {
    const [overArr, setOverArr] = useState([]);
    const [open, setOpen] = useState(false);
    const [viewUserId, setViewUserId] = useState(null);
    const {t} = useTranslation();
    const [checkDelete, setCheckDelete] = useState(null);

    useEffect(() => {
        let arr = [];
        if (courseData?.lessons.length < 7) {
            for (let i = courseData?.lessons.length; i < 7; i++) {
                arr.push({})
            }
            setOverArr(arr);
        }
    }, [courseData])

    const userPresent = (lesson, userId) => {
        let users = lesson.user_present?.filter(item => item.directus_users_id === userId);
        return users?.length > 0 ? users[0] : null;
    }

    function addLesson() {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/course/add-lesson`;
        api.post(apiUrl, {
            course_id: courseData.id
        }).then(response => {
            toast.success(t('addLessonSuccess'));
            onReload(true);
        }).catch(err => {
            console.log(err)
            toast.error(err.response.data.message)
        })
    }

    function attendance(lesson, userId) {
        let user = userPresent(lesson, userId);
        let body;
        if (user) {
            body = {
                user_present: {
                    delete: [user.id]
                }
            };
        } else {
            body = {
                user_present: {
                    create: [
                        {
                            lesson_id: lesson.id,
                            directus_users_id: {id: userId}
                        }
                    ]
                }
            };
        }
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/items/lesson/${lesson.id}`;
        api.patch(apiUrl, body).then(response => {
            onReload(true);
        })
    }

    const openDialog = (userId) => {
        setViewUserId(userId);
        setOpen(true);
    }

    function onCloseDialog() {
        setViewUserId(null);
        setOpen(false);
    }

    function deleteLesson(id) {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/items/lesson/${id}`;
        api.delete(apiUrl).then(response => {
            toast.success(t('deleteLessonSuccess'));
            onReload(true);
        })
    }

    return (
        <div className='p-[20px] attendance bg-[#FFFFFF] h-full'
            style={{borderRadius: '0 8px 8px 8px'}}>
            <div className="overflow-x-auto">
                <table className="table-fixed w-full overflow-y-auto"
                       style={{borderCollapse: 'separate', borderSpacing: 0}}>
                    <thead>
                        <tr key={0}>
                            <th className={`border-top border-right border-left border-[#D5D5D5] p-2 w-[280px] sticky left-0 bg-[#F6F6F6]`}
                                style={{borderStartStartRadius: '10px'}}
                                key='attendance'
                            >{t("attendance")}
                            </th>
                            {courseData?.lessons.map((item) => (
                                <th className="border-top border-right border-[#D5D5D5] p-2 w-[125px]" key={item.id}>
                                    <div className='group flex justify-center'>
                                        <span>{item.title}</span>
                                        {!isFinished && <div className="pl-2 flex justify-center">
                                            {checkDelete === item.id ? (
                                            <>
                                                <CheckIcon fontSize="small" className="cursor-pointer hover:text-[#F0C528]"
                                                           onClick={() => deleteLesson(item.id)}/>
                                                <CloseIcon fontSize="small" className="cursor-pointer hover:text-[#F0C528]"
                                                           onClick={() => setCheckDelete(null)}/>
                                            </>
                                            ) : (
                                                <img src="/Images/delete.svg" alt="" title={t('deleteLesson')}
                                                     className='cursor-pointer group-hover:block hidden ml-4'
                                                     onClick={() => setCheckDelete(item.id)}/>
                                            )}
                                        </div>}
                                    </div>
                                </th>
                            ))}
                            <th className={`border-top border-right ${ overArr.length === 0 ? 'border-right' : ''} border-[#D5D5D5] p-2 w-[125px]`}
                                style={{borderStartEndRadius: overArr.length === 0 ? '10px' : ''}}
                                key='add'>
                                <div className='grid justify-items-center'>
                                    {!isFinished && <img src="/Images/add.svg" alt="" onClick={addLesson}
                                         className='cursor-pointer'/>}
                                </div>
                            </th>
                            {overArr.map((item, i) => (
                                <th className={`border-top border-right ${ overArr.length - 1 === i ? 'border-right' : ''} border-[#D5D5D5] p-2 w-[125px]`}
                                    style={{borderStartEndRadius: overArr.length - 1 === i ? '10px' : ''}}
                                    key={i}>&nbsp;</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {userAttendance?.map((user, index) => (
                        <tr key={user.directus_users_id.id}>
                            <td className={`border-top border-right border-left ${index === userAttendance.length - 1 ? 'border-bottom' : ''} border-[#D5D5D5] p-2 w-[280px] sticky left-0 group bg-[#F6F6F6]`}
                                style={{borderEndStartRadius: index === userAttendance.length - 1 ? '10px' : ''}}>
                                <div className='flex justify-between'>
                                    <div className='flex'>
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/assets/${user.directus_users_id.avatar}`}
                                            className='w-[28px] h-[28px]'
                                            alt="Avatar"
                                        />
                                        <div>&nbsp;&nbsp;{user.directus_users_id.last_name} {user.directus_users_id.first_name}</div>
                                    </div>
                                    <img src="/Images/noteIcon.svg" alt="" title={t('noteTitle')}
                                         className='cursor-pointer group-hover:block hidden filter-black'
                                         onClick={() => openDialog(user.directus_users_id.id)}/>

                                </div>
                            </td>
                            {courseData?.lessons.map((lesson, i) => (
                                <td className={`border-top border-right ${index === userAttendance.length - 1 ? 'border-bottom' : ''} border-[#D5D5D5] p-2 w-[125px]`} key={lesson.id}>
                                    <input type='checkbox' disabled={isFinished} checked={userPresent(lesson, user.directus_users_id.id) !== null} onChange={() => attendance(lesson, user.directus_users_id.id)}/>
                                </td>
                            ))}
                            <td  className={`border-top border-right ${index === userAttendance.length - 1 ? 'border-bottom' : ''} border-[#D5D5D5] p-2 w-[125px]`}
                                style={{borderEndEndRadius: index === userAttendance.length - 1 && overArr.length === 0 ? '10px' : ''}}>&nbsp;</td>
                            {overArr.map((item, i) => (
                                <td className={`border-top border-right ${index === userAttendance.length - 1 ? 'border-bottom' : ''} border-[#D5D5D5] p-2 w-[125px]`}
                                    style={{borderEndEndRadius: index === userAttendance.length - 1 && overArr.length - 1 === i ? '10px' : ''}}
                                    key={i + courseData?.lessons.length + 1}>&nbsp;</td>
                            ))}
                        </tr>
                    ))}

                    {(!userAttendance || userAttendance.length === 0) &&
                    <tr>
                        <td className={`border-top border-right border-left border-bottom border-[#D5D5D5] p-2 w-[280px] sticky left-0 group bg-[#F6F6F6]`}
                            style={{borderEndStartRadius: '10px'}}>
                        </td>
                        {courseData?.lessons.map((lesson, i) => (
                            <td className={`border-top border-right border-bottom border-[#D5D5D5] p-2 w-[125px]`} key={lesson.id}>

                            </td>
                        ))}
                        <td  className={`border-top border-right border-bottom border-[#D5D5D5] p-2 w-[125px]`}
                             style={{borderEndEndRadius: overArr.length === 0 ? '10px' : ''}}>&nbsp;</td>
                        {overArr.map((item, i) => (
                            <td className={`border-top border-right border-bottom border-[#D5D5D5] p-2 w-[125px]`}
                                style={{borderEndEndRadius: overArr.length - 1 === i ? '10px' : ''}}
                                key={i + courseData?.lessons.length + 1}>&nbsp;</td>
                        ))}
                    </tr>}
                    </tbody>
                </table>

            </div>

            <Dialog
                open={open}
                onClose={onCloseDialog}
                maxWidth="lg"
                scroll="paper"
            >
                <DialogContent>
                    <div className='w-[1200px]'>
                        <NoteDetails isDialog={true} isView={true} viewUserId={viewUserId} onCloseDialog={() => onCloseDialog()}/>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default Attendance;
