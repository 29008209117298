import DeleteIcon from '@mui/icons-material/Delete';
import {grey} from '@mui/material/colors';
import {yellow} from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import React, {useState, useEffect, useCallback} from "react";
import '../App.css'
import InfoIcon from '@mui/icons-material/Info';
import ContentEditable from 'react-contenteditable';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import debounce from "lodash/debounce";
import {useTranslation} from "react-i18next";
import {showToast} from '../common/Toast'
import Tooltip from "@mui/material/Tooltip";

const Note = ({
                  courseType,
                  courseData = [],
                  idNoted,
                  setIsVisible,
                  setIsCancelled,
                  onAddItem,
                  onDeleteItem,
                  isInfoVisible,
                  setIsInfoVisible,
                  isDialog,
                  isView,
                  onCloseDialog,
                  isAttendance,
                  isCompletion,
                  isCompanyLearningHour,
                  isCompensation,
                  compensationTime
              }) => {
    const {t} = useTranslation()
    const handleKeyDownTitle = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const textarea = document.getElementById('textarea'); // Add an id to the textarea element
            textarea.focus();
        }
    };
    const [items, setItems] = useState(courseData);
    const [inputValue, setInputValue] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [noteData, setNoteData] = useState("")

    useEffect(() => {
        setItems(courseData);
        // Set the first item in the courseData array as the selected item
        if (courseData.length > 0) {
            handleItemClick(courseData[0]);
        }
    }, [courseData])

    const formatDate = (date) => {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    const handleAddItem = () => {
        let newItem = null;
        if (isCompensation) {
            newItem = {
                title: 'Học bù ' + compensationTime,
                note: `Tôi đã học được gì:

Tôi có thể áp dụng gì vào công việc:`,
                course_id: parseInt(idNoted),
                note_type: "COMPENSATION_NOTE"
            }
        } else if (isCompanyLearningHour) {
            newItem = {
                title: 'Company Learning Hour ngày ' + formatDate(new Date()),
                note: `Tôi đã học được gì:

Tôi có thể áp dụng gì vào công việc:`,
                course_id: parseInt(idNoted),
                note_type: "WORK_STUDY_NOTE"
            }
        } else {
            newItem = {
                title: 'Tiêu đề',
                    note: `Tôi đã học được gì:

Tôi có thể áp dụng gì vào công việc:`,
                course_id: parseInt(idNoted),
                note_type : "COURSE_NOTE"
            };
        }
        onAddItem(newItem);
    };

    const handleDeleteItem = id => {
        onDeleteItem(id);
    };

    const handleInfoAction = () => {
        setIsInfoVisible(!isInfoVisible);
        setIsCancelled(false);
        setIsVisible(true);
    }

    // Sending Learning Time
    const compareDate = useCallback((dateString) => {
        const date1 = new Date(dateString);
        const date2 = new Date();

        const diffInSeconds = Math.floor((date2 - date1) / 1000);

        if (diffInSeconds < 60) {
            return t('justTime');
        } else if (diffInSeconds < 3600) {
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            return `${diffInMinutes} ${t('minuteAgo')}`;
        } else if (diffInSeconds < 86400) {
            const diffInHours = Math.floor(diffInSeconds / 3600);
            return `${diffInHours} ${t('hourAgo')}`;
        } else if (diffInSeconds <= 864000) {
            const diffInDays = Math.floor(diffInSeconds / 86400);
            return `${diffInDays} ${t('dayAgo')}`;
        }
        return `${date1.getDate()}/${date1.getMonth()+1}/${date1.getFullYear()}`
    }, [t])

    const updateNote = (key, value) => {
        const updatedItems = [...items];
        const updatedItemIndex = updatedItems.findIndex(
            (item) => item.id === selectedItemId
        );
        if (updatedItemIndex !== -1) {
            updatedItems[updatedItemIndex][key] = value;
            setItems(updatedItems);
            const storedAccessToken = localStorage.getItem('accessToken');

            fetch(`${process.env.REACT_APP_BASE_URL}/items/note/${selectedItemId}`, {
                method: "PATCH",
                body: JSON.stringify({[key]: value}),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${storedAccessToken}`,
                },
            })
                .catch((error) => {
                    showToast(t("error"), "warning")
                });
        }
    }

// Catch Change and updateNote//
    const handleUpdate = debounce((key, value) => {
            // Update items state with the new value
            updateNote(key, value);
        }, 1000);
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        handleUpdate("title", inputValue);
    };
    const handleInputChangeBody = (e) => {
        const inputValue = e.target.value;
        setNoteData(inputValue);
        handleUpdate("note", inputValue);
    };
    // Close Change and updateNote//

    const handleItemClick = (item) => {
        setSelectedItemId(item.id);
        if (item.note === null) {
            setInputValue(item.title || "");
        } else {
            setInputValue(item.title);
            setNoteData(item.note)
        }
    };

    function replaceSpecialCharacters(str) {
        if (str === undefined || str === null) return '';
        return str
            .replace(/&nbsp;/g, '')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<');
    }

    const [checkDelete, setCheckDelete] = useState(null);

    return (
        <>
            <div
                id="hideScroll"
                className="w-3/12 border-solid shrink-0 overflow-y-auto border-r-2 border-[#dddddd] bg-[#585858]"
                style={{
                    borderWidth: "0",
                    borderRadius: "1rem 0px 0px 1rem",
                }}>
                <div className="bg-[#585858] border-b-2 border-solid border-[#979696]">
                    <div className="p-6">
                        <div className="flex justify-between">
                            <div className="flex items-center">
                                <img src="/Images/noteIcon.svg" alt=""/>
                                <div className="flex items-center ml-2">
                                    <div className="font-bold text-xl text-[#F4F4F4]">{t("notes")}</div>
                                    {courseType === "COURSE" && <Tooltip title={t('courseTypeInfo')}
                                                                         placement="top">
                                        <img src="/Images/info.svg" alt='' className="pl-1"/>
                                    </Tooltip>
                                    }
                                </div>
                            </div>
                            {isCompletion || isView || isDialog || courseType === "COURSE" ? null : (
                                <AddIcon sx={{color: yellow[500]}} fontSize="large" onClick={handleAddItem}
                                         className="cursor-pointer"/>
                            )}
                        </div>
                        <div
                            className="bg-[#585858] text-left text-[#D5D5D5] text-sm font-normal">
                            {courseData.length} {t("notes")}
                        </div>
                    </div>
                </div>
                <div className="overflow-y-scroll noteScroll">
                    {items?.map((item) => (
                        <div
                            key={item.id}
                            onClick={() => handleItemClick(item)}
                            className={`sm:w-full cursor-pointer bg-[#585858] hover:bg-[#979696] border-b-2 border-solid border-[#979696] ${
                                item.id === selectedItemId ? 'bg-[#979696] border-b-2 border-solid border-yellow-300' : ''
                            } p-6 text-left group`}
                        >
                            <div className="flex justify-between">
                                <div
                                    className="text-[#F4F4F4] text-sm font-bold line-clamp-2">{replaceSpecialCharacters(item.title)}</div>
                                {(item.note_type === "WORK_STUDY_NOTE" || item.note_type === "COMPENSATION_NOTE") &&
                                <img src="/Images/Star.svg" title="Company Learning Hour note" alt="Company Learning Hour note"/>
                                }
                            </div>

                            <div className="flex justify-between">
                                <div
                                    className="text-[#D5D5D5] text-xs font-medium">{compareDate(item.date_created)}</div>
                                {!isCompletion && !isView && courseType !== "COURSE" &&
                                <div className={checkDelete === item.id ? 'block' : 'group-hover:block hidden'}>
                                    {checkDelete === item.id ? (
                                        <>
                                            <CheckIcon fontSize="small" sx={{color: grey[100]}}
                                                       onClick={() => handleDeleteItem(item.id)}/>
                                            <CloseIcon fontSize="small" sx={{color: grey[100]}}
                                                       onClick={() => setCheckDelete(null)}/>
                                        </>
                                    ) : (
                                        <DeleteIcon fontSize="small" sx={{color: grey[100]}}
                                                    onClick={() => setCheckDelete(item.id)}/>
                                    )}
                                </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/*//////////////////////////Default img/////////////////////////////////*/}
            {courseData.length === 0 && (
                <div className="relative w-full">
                    <img src="/Images/defaultNoteImg.png" alt="Default" className="h-full w-full"/>
                    {isInfoVisible &&
                    <div className="absolute top-0 right-0 m-2 cursor-pointer"
                         onClick={handleInfoAction}>
                        <InfoIcon/>
                    </div>
                    }
                </div>
            )}
            {/*//////////////////////////  Where to Type/////////////////////////////////*/}
            {courseData.length > 0 && (
                <div className="w-9/12 relative">
                    <div className="overflow-y-auto h-full flex flex-col" id="hideScroll">
                        <div className="flex pb-4 w-full" style={{flex: '0 1 auto'}}>
                            <ContentEditable
                                html={inputValue}
                                disabled={isView || isCompletion}
                                onChange={handleInputChange}
                                className="font-normal font-bold:text-bold text-lg w-full px-8 py-2 rounded-r-md block h-full text-left"
                                style={{
                                    border: "none",
                                    outline: "none",
                                    padding: "40px 40px 0px 40px",
                                    borderRadius: "0px 16px 16px 0px",
                                    fontWeight: "700",
                                    fontSize: "24px",
                                }}
                                onKeyDown={handleKeyDownTitle}
                            />
                            {!isDialog && isInfoVisible && (
                                <div className="relative cursor-pointer">
                                    <InfoIcon className="absolute right-0 top-0 m-2" onClick={handleInfoAction}/>
                                </div>
                            )}
                            {isDialog &&
                            <div className="relative cursor-pointer">
                                <CloseIcon className="absolute right-0 top-0 m-2" onClick={onCloseDialog}/>
                            </div>}
                        </div>
                        <textarea type="text"
                                  id="textarea"
                                  disabled={isView || isCompletion}
                                  className={`placeholder-gray-500 font-normal font-bold:text-bold text-lg w-full px-8 py-2 rounded-r-md h-[50vh] ${isAttendance ? 'bg-[#FFFFFF]' : 'bg-[#F5F5F5]'}`}
                                  style={{
                                      border: "none",
                                      outline: "none",
                                      padding: "0px 40px 0px 40px",
                                      borderRadius: "0px 16px 16px 0px",
                                      resize: "none",
                                      flex: '1 1 auto'
                                  }}
                                  value={noteData}
                                  onChange={handleInputChangeBody}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Note;

