import React, {useEffect, useState, useRef} from "react"
import {Outlet, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import SchoolIcon from '@mui/icons-material/School';
import EditNoteIcon from '@mui/icons-material/EditNote';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./LangSwitch.css"
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Menu} from 'antd';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CourseCard from "./courseCard";
import api from "../axios";
import moment from "moment";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type
    };
}

const HomePage = (props) => {
    const {t} = useTranslation();
    const [userInfo, setUserInfo] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const storedAccessToken = localStorage.getItem('accessToken');
    const dropdownRef = useRef(null);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng') || 'vn');
    const [open, setOpen] = useState(false);
    const [isLearningHour, setIsLearningHour] = useState(false);
    const [isCompensationHour, setIsCompensationHour] = useState(false);
    const [compensationTime, setCompensationTime] = useState('');
    const scrollableRef = useRef(null);
    const [showCompensation, setShowCompensation] = useState(false);

    const items = [
        getItem(t("language"), "sub2", null, [
            getItem("Tiếng việt", "vi", <img src="/Images/vnLogo.svg" alt="vn"/>),
            getItem("English", "en", <img src="/Images/engLogo.svg" alt="en"/>),
        ]),
    ];

    useEffect(() => {
        if (!localStorage.getItem("i18nextLng")) {
            localStorage.setItem('i18nextLng', 'en');
        } else {
            setSelectedLanguage(localStorage.getItem('i18nextLng'));
        }
        if (!storedAccessToken) {
            navigate("/")
        }
        const fetchData = async () => {
            const apiUrl = `${process.env.REACT_APP_BASE_URL}/users/me?fields=*,role.*`;
            api.get(apiUrl).then(response => {
                const userID = response.data.data.id
                localStorage.setItem('userID', userID);
                localStorage.setItem('role', response.data.data.role ? response.data.data.role.code : '')
                setUserInfo(response.data.data);
            }).catch (error => {
                console.error(error);
            });
        };

        fetchData();
        const currentDate = new Date();
        const currentDay = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
        setIsLearningHour(false);
        api.get(`${process.env.REACT_APP_BASE_URL}/items/company_learning_hour?filter[start_date][_lte]=${currentDay}&filter[end_date][_gte]=${currentDay}`).then(response => {
            if (response?.data?.data?.length > 0) {
                const fromDate = new Date();
                fromDate.setDate(fromDate.getDate() - (currentDay - response.data.data[0].start_date));
                const toDate = new Date();
                toDate.setDate(toDate.getDate() + (response.data.data[0].end_date - currentDay));
                api.get(`${process.env.REACT_APP_BASE_URL}/items/note?filter[_and][0][_and][0][_and][0][user_created][_eq]=$CURRENT_USER&filter[_and][0][_and][0][_and][1][note_type][_eq]=WORK_STUDY_NOTE&filter[_and][0][_and][0][_and][2][date_created][_gte]=${fromDate.toISOString().split('T')[0]}&filter[_and][0][_and][0][_and][3][date_created][_lte]=${toDate.toISOString().split('T')[0]}`).then(result => {
                    setIsLearningHour(result?.data?.data?.length === 0);
                });
            }
        });

        api.get(`${process.env.REACT_APP_BASE_URL}/learning-compensation/check`).then(result => {
            setIsCompensationHour(result.data.learningCompensationTime);
            if (result.data.learningCompensationTime) {
                const toDate = new Date();
                let currentDate = moment(toDate).format("YYYY-MM-DD");
                let currentTime = moment(toDate).format("HH:mm:ss");
                api.get(`${process.env.REACT_APP_BASE_URL}/items/learning_compensation?filter[_and][0][from_date][_lte]=${currentDate}&filter[_and][1][to_date][_gte]=${currentDate}&filter[_and][2][from_time][_lte]=${currentTime}&filter[_and][3][to_time][_gte]=${currentTime}`).then(time => {
                    if (time.data.data.length > 0) {
                        const lang = localStorage.getItem('i18nextLng');
                        const formatter = new Intl.DateTimeFormat('en', {month: 'short'});
                        let month = time.data.data[0].month;
                        let year = time.data.data[0].year;
                        setCompensationTime((lang === 'vi' ? `T${month}` : formatter.format(new Date(year, month, 1))) + `/${year}`);
                    }
                }, error => {
                    setCompensationTime('');
                    setIsCompensationHour(false);
                });
            }
        }, error => {
            setCompensationTime('');
            setIsCompensationHour(false);
        });
    }, [setSelectedLanguage, navigate, storedAccessToken]);

    const handleBackToHomePage = () => {
        navigate("/home");
    };

    const goToStatistical = () => {
        navigate("/statistical");
    }

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleLogout = () => {
        props.handleSignOut();
    };

    const handleProfile = () => {
        navigate("/profile");
        handleDropdownToggle();
    }

    const handleBlur = () => {
        setTimeout(() => {
            setShowDropdown(false);
        }, 100);
    };

    const handleLanguage = (e) => {
        window.location.reload();
        setSelectedLanguage(e.key);
        localStorage.setItem('i18nextLng', e.key);
        i18next.changeLanguage(e.key);
    }

    return (
        <>
            <div className="px-[5%] mx-auto flex justify-between items-center p-4 pt-8 bg-[#F5F5F5]">
                <div className="flex items-center cursor-pointer">
                    <h1 className="text-2xl sm:text-3xl lg:text-4xl px-2" onClick={handleBackToHomePage}>
                        Bi<span className="font-bold">Note</span>
                    </h1>
                </div>
                {/*Middle*/}
                <div className="bg-[#2F2E2E] hidden lg:flex items-center rounded-2xl p-4 text-[14px]">
                    <div
                        className={`flex items-center cursor-pointer ${window.location.pathname === '/statistical' ? 'text-[#F0C528]' : ''}`}
                        onClick={goToStatistical}>
                        <DashboardIcon
                            sx={{color: window.location.pathname === '/statistical' ? '#F0C528' : '#979696'}}/>
                        <p
                            className={`text-[#979696] rounded-full p-2 hover:text-[#F0C528] ${
                                window.location.pathname === '/statistical' ? 'text-[#F0C528]' : ''
                            }`}
                        >
                            Dashboard
                        </p>
                    </div>
                    <p className="w-[22px] border border-solid rotate-90"></p>
                    <div
                        className={`flex items-center cursor-pointer ${window.location.pathname === '/home' ? 'text-[#F0C528]' : ''}`}
                        onClick={handleBackToHomePage}>
                        <SchoolIcon sx={{color: window.location.pathname === '/home' ? '#F0C528' : '#979696'}}/>
                        <p
                            className={`text-[#979696] rounded-full p-2 hover:text-[#F0C528] ${
                                window.location.pathname === '/home' ? 'text-[#F0C528]' : ''
                            }`}
                        >
                            {t("course")}
                        </p>
                    </div>
                    {window.location.pathname === '/home' && isCompensationHour && <p className="w-[22px] border border-solid rotate-90"></p>}
                    {window.location.pathname === '/home' && isCompensationHour && <div
                        className={`flex items-center cursor-pointer`}
                        onClick={() => {setShowCompensation(true); setOpen(true)}}>
                        <EditNoteIcon sx={{color: '#979696'}}/>
                        <p
                            className={`text-[#979696] rounded-full p-2 hover:text-[#F0C528]`}
                        >
                            {t("compensation") + " " + compensationTime}
                        </p>
                    </div>}

                </div>
                {/*right avatar*/}
                <div className="item-center py-2 cursor-pointer relative z-50">
                    <div className="flex float-right" onClick={handleDropdownToggle} onBlur={handleBlur}
                         ref={dropdownRef}>
                        {userInfo && (
                            <div className="flex items-center">
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/${userInfo.avatar}`} alt=""
                                     className="w-[32px] h-[32px] rounded object-cover"/>
                                <h3 className="pl-2 inline-flex items-center">
                                    {userInfo.last_name} {userInfo.first_name}
                                </h3>
                                <div className="ml-auto">
                                    <KeyboardArrowDownIcon className=""/>
                                </div>
                            </div>
                        )}
                    </div>
                    {showDropdown && (
                        <div className="dropdown-menu absolute bg-white rounded shadow-lg mt-2 w-[200px]">
                            <div className="dropdown-item py-2 px-4 hover:bg-gray-200 cursor-pointer text-left flex justify-start"
                                 onClick={handleProfile}>
                                <img src="/Images/profile.svg" alt=''/>
                                <span className="pl-2">{t("profile")}</span>
                            </div>
                            <div
                                className="flex dropdown-item py-2 px-4 hover:bg-gray-200 cursor-pointer border-b text-left"
                                onClick={handleLogout}>
                                <img src="/Images/logout.svg" alt=''/>
                                <span className="pl-2">{t("signOut")}</span>
                            </div>
                            <Menu onClick={handleLanguage} mode="vertical" items={items}
                                  defaultSelectedKeys={[selectedLanguage]}/>
                        </div>
                    )}
                </div>
            </div>

            {window.location.pathname === '/home' && isLearningHour &&
                <div className={`learning-hour hover:scale-105 duration-300 cursor-pointer ${open ? 'hidden' : ''}`}>
                    <img src="/Images/Learning hour.png" alt="Company Learning Hour" onClick={() => setOpen(true)}/>
                </div>
            }
            <Dialog
                open={open}
                onClose={() => {setOpen(false); setShowCompensation(false)}}
                maxWidth="lg"
                scroll="paper"
            >
                <DialogTitle>
                    <div className="relative pb-5">
                        <div className="absolute right-0">
                            <CloseIcon className="cursor-pointer" onClick={() => setOpen(false)}/>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="w-[1061px] px-6" ref={scrollableRef}>
                        <img src="/Images/Learning hour panel.png" className="pb-4 w-full" alt="Company Learning Hour"/>
                        <CourseCard isDialog={true} isCompanyLearningHour={true} scrollableRef={scrollableRef} isCompensation={showCompensation} compensationTime={compensationTime}/>
                    </div>
                </DialogContent>
            </Dialog>
            <Outlet/>
        </>
    )
}
export default HomePage;
