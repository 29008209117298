import React, {useState, useEffect, useCallback} from "react"
import axios from "axios"
import SearchIcon from '@mui/icons-material/Search';
import RecentlyCourses from "./recentlyCourses";
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next";
import api from "../axios";
import debounce from "lodash/debounce";

const CourseCard = (props) => {
    const {t} = useTranslation()
    const [storedAccessToken, setStoredAccessToken] = useState(null);
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        setStoredAccessToken(accessToken);
    }, []);
    const LIMIT_DATA = 12;
    const navigate = useNavigate()
    const [dataSource, setDataSource] = useState([])
    const [courses, setCourses] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const isDialog = props.isDialog;
    const isCompanyLearningHour = props.isCompanyLearningHour;
    const scrollableRef = props.scrollableRef;
    const isCompensation = props.isCompensation;
    const compensationTime = props.compensationTime;
    const [effect, setEffect] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(true);
    const [search, setSearch] = useState('');

    const fetchData = useCallback(
        async (id = 0, searchQuery = '', reset = false) => {
            let data = dataSource;
            let loadingMore = isLoadingMore;
            if (reset) {
                data = [];
                loadingMore = true
                setIsLoadingMore(loadingMore);
            }
            if (!loadingMore) {
                return;
            }
            try {
                const payload = {
                    title: searchQuery,
                    category_id: [id],
                    exclude_id: []
                };
                const config = {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${storedAccessToken}`
                    }
                };
                setIsLoading(true);
                let paging = data.length === 0 ? 1 : (Math.floor(data.length / LIMIT_DATA) + 1);
                const response = await api.post(
                    `${process.env.REACT_APP_BASE_URL}/flows/trigger/6871f313-a5e3-4edd-917b-6217451e01b9?page=${paging}&limit=${LIMIT_DATA}&sort=sort&sort=-id`,
                    payload,
                    config
                );
                data.push(...response.data.data)
                if (data.length >= response.data.total_count) {
                    setIsLoadingMore(false);
                }
                setDataSource(data)
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        },
        [storedAccessToken, isLoadingMore, dataSource]
    );

    useEffect(() => {
        const handleScroll = () => {
            const isScrolledToBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
            if (isScrolledToBottom && !isLoading) {
                fetchData(selectedCategoryId)
            }
        };

        const handleScrollDialog = () => {
            if (!scrollableRef || !document.getElementsByClassName("MuiDialogContent-root")) return;
            const { scrollHeight, scrollTop, clientHeight } = document.getElementsByClassName("MuiDialogContent-root")[0];
            const isScrolledToBottom = scrollHeight - scrollTop >= clientHeight;
            if (isScrolledToBottom && !isLoading) {
                fetchData(selectedCategoryId)
            }
        };
        window.addEventListener('scroll', isDialog ? handleScrollDialog : handleScroll);
        if (isDialog) {
            handleScrollDialog()
        } else {
            handleScroll()
        }
        return () => {
            window.removeEventListener('scroll', isDialog ? handleScrollDialog : handleScroll);
        };
    }, [fetchData, isLoading, selectedCategoryId, isDialog, scrollableRef]);

    if (effect) {
        setEffect(false);
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/items/category`, {})
            .then((res) => {
                setCourses(res.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleSearch = debounce((inputValue) => {
        // Update items state with the new value
        fetchData(selectedCategoryId, inputValue, true)
    }, 100);

    const handleSearchChange = (e) => {
        const inputValue = e.target.value;
        setSearch(inputValue);
        handleSearch(inputValue);
    };

    const handleButtonClick = (id) => {
        setSelectedCategoryId(id);
        fetchData(id, search, true);
    };

    const handleNoteDetails = (item) => {
        navigate(`/note/${item.id}`, { state: { item, isCompanyLearningHour, isCompensation, compensationTime } });
    };

    return (<>
            {!isDialog &&
                <>
                    <RecentlyCourses></RecentlyCourses>
                    <div className="bg-[#F5F5F5]">
                        <div className="border border-solid border-[#D5D5D5] mx-[5%]"></div>
                    </div>
                    {/*Course catalog*/}
                    <div className="flex flex-wrap pt-10 pb-6 gap-4 px-[5%] mx-auto bg-[#F5F5F5]">
                        <button
                            onClick={() => handleButtonClick()}
                            className={`h-[39px] hover:bg-[#2F2E2E] border-[#D5D5D5] rounded-lg hover:text-[#F0C528] ${
                                selectedCategoryId === null ? "bg-[#2F2E2E] text-[#F0C528]" : ""}`}
                        >{t("all")}
                        </button>
                        {courses.map((item) => (
                            <button
                                onClick={() => handleButtonClick(item.id)}
                                key={item.code}
                                className={`h-[39px] hover:bg-[#2F2E2E] border-[#D5D5D5] rounded-lg hover:text-[#F0C528] ${
                                    selectedCategoryId === item.id ? "bg-[#2F2E2E] text-[#F0C528]" : ""}`}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                </>
            }

            {/*Searching*/}
            <div className={`${isDialog ? '' : 'px-[5%]'} mx-auto w-full bg-[#F5F5F5]`}>
                <div className="relative flex">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                        <SearchIcon className="text-gray-400"/>
                    </div>
                    <input
                        type="text"
                        placeholder={t("search")}
                        className="bg-white border-solid border-[#D5D5D5] border rounded-lg w-full h-[52px] pl-10 pr-3 text-left"
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            {/*Courses*/}
            <div>
                <div
                    className={`cursor-pointer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pt-4 pb-14 ${isDialog ? '' : 'px-[5%]'} mx-auto bg-[#F5F5F5]`}>
                    {dataSource?.map((item, index) => (
                        <div
                            key={index}
                            className='border shadow-md rounded-lg hover:scale-105 duration-300 bg-white'
                            onClick={() => handleNoteDetails(item)}
                        >
                            <div className="p-4">
                                <img
                                    key={item.image}
                                    src={`${process.env.REACT_APP_BASE_URL}/assets/${item.image}`}
                                    alt={item?.name}
                                    className='w-full rounded h-[150px] object-cover rounded-t-lg'
                                />
                            </div>
                            <p className='font-bold flex justify-between px-4 pb-4 text-sm text-left overflow-ellipsis'>
                                <span>{item?.title}</span>
                            </p>
                            <div className='flex justify-between px-4 pb-4'>
                                <p className="inline-flex items-center">
                                    <img className='filter-black' src="/Images/noteIcon.svg" alt=""/>
                                    <span className='p-1'>
                        {item?.notes_count} notes
                        </span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isLoading &&
            <div className="bg-[#F5F5F5] w-full">
                <span className="loader"></span>
            </div>}
        </>
    );
}
export default CourseCard
