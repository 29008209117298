import React, {useCallback, useEffect, useState} from "react";
import HomePage from "../HomePage/homePage";
import {useTranslation} from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {DatePicker} from 'antd';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Table, Typography} from 'antd';
import "../App.css"
import dayjs from 'dayjs';
import FeaturedCourse from "./ Featured_course";
import CardInfo from "./Card_info";
import {Spin} from 'antd';
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
import Progressbar from "./Progress_bar"
import api from "../axios";
import {styled, tooltipClasses} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const {Text} = Typography;
const {RangePicker} = DatePicker;
const monthFormat = 'YYYY/MM';
const currentYear = dayjs().year();
const currentMonth = dayjs().month() + 1; // Months are zero-based, so we add 1
const firstMonth = dayjs(`${currentYear}-${currentMonth}`).month(currentMonth - 6).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
const lastMonth = dayjs(`${currentYear}-${currentMonth}`).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

const convertToTimestamp = (dateString, isEndDate) => {
    const date = new Date(dateString);
    date.setDate(1);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = isEndDate ? String(new Date(year, month, 0).getDate()).padStart(2, '0') : String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = isEndDate ? "23:59:59.999Z" : "00:00:00.000Z";
    const timestamp = `${formattedDate}T${formattedTime}`;

    return timestamp;
}

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2F2E2E',
        color: 'white',
        maxWidth: 130,
        border: '1px solid #2F2E2E',
        borderRadius: '8px'
    },
}));

const Statistical = (props) => {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(firstMonth);
    const [endDate, setEndDate] = useState(lastMonth);
    const disabledMonth = (current) => {
        const currentDate = new Date();
        return current.isAfter(currentDate, 'month');
    };
    const {t} = useTranslation()
    const [selectedValue, setSelectedValue] = useState('individual');
    const [type, setType] = useState('month');
    const [typeTeach, setTypeTeach] = useState('month');
    const [dataSource, setDataSource] = useState([]);
    const [totalLearningHours, setTotalLearningHours] = useState([]);
    const [renderTitleTable, setRenderTitleTable] = useState([])
    const [effect, setEffect] = useState(true);
    const [search, setSearch] = useState('');
    const [teachList, setTeachList] = useState([]);
    const [kpi, setKpi] = useState(0);
    const [date, setDate] = useState( dayjs(`${currentYear}-${currentMonth}`).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z');
    const navigate = useNavigate()
    const checkHR = () => {
        return localStorage.getItem("role") === 'HR';
    }

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    const handleDateRangeChange = (date) => {
        if (date && date.length === 2) {
            const fromDate = date[0];
            const toDate = date[1];

            if (fromDate && toDate) {
                if (!isNaN(fromDate.$d) && !isNaN(toDate.$d)) {
                    let from = new Date(fromDate.$d);
                    let to = new Date(toDate.$d);
                    let diffMonth = monthDiff(from, to);
                    if (diffMonth > 23) {
                        toast.warning(t('min24months'));
                        return;
                    }
                    let fromTimestamp = convertToTimestamp(fromDate.$d, false)
                    to.setMonth(to.getMonth() + 2)
                    let toTimestamp = type === 'month' ? convertToTimestamp(toDate.$d, true) :  convertToTimestamp(to, true)
                    setStartDate(fromTimestamp);
                    setEndDate(toTimestamp);
                } else {
                    console.log('Invalid date format');
                }
            } else {
                console.log('Invalid date values');
            }
        }
    };

    const handleDateChange = (date) => {
        if (date) {
            if (!isNaN(date.$d)) {
                setDate(convertToTimestamp(date.$d, false));
            } else {
                console.log('Invalid date format');
            }
        } else {
            console.log('Invalid date values');
        }
    };

    function round(number) {
        if (isNaN(number)) {
            return "0";
        }
        let numFloat = parseFloat(number).toFixed(1)
        return Math.ceil(numFloat) === Math.floor(numFloat) ? parseInt(number) : numFloat;
    }

    const accessToken = localStorage.getItem('accessToken');

    const sendDataTable = useCallback(async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/statistical-for-admin`;
        const requestData = {
            from_date: startDate,
            to_date: endDate,
            option: selectedValue,
            type: type,
            name: search
        };
        try {
            setLoading(true);
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(requestData)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setDataSource(data.data);
            setTotalLearningHours(data.totalLearningHours)
            const listMonthForChart = data.timePeriods.map(item => item.period)
            setRenderTitleTable(listMonthForChart);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [startDate, endDate, accessToken, selectedValue, type, search]);

    const fetchTeachHour = useCallback(() => {
        let fromDate;
        let toDate;
        let d = new Date(date);
        if (typeTeach === 'month') {
            fromDate = dayjs(`${d.getFullYear()}-${d.getMonth() + 1}`).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
            toDate = dayjs(`${d.getFullYear()}-${d.getMonth() + 1}`).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        } else {
            fromDate = dayjs(`${d.getFullYear()}-${d.getMonth()+1}`).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
            toDate = dayjs(`${d.getFullYear()}-${d.getMonth() + 3}`).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        }

        let url = `${process.env.REACT_APP_BASE_URL}/manager-teaching-hour?from_date=${fromDate}&to_date=${toDate}`;
        api.get(url)
            .then(response => {
                setTeachList(response.data)
                if (response.data?.length > 0 && response.data[0].kpi > 0) {
                    setKpi(response.data[0].kpi)
                }
            })
    }, [date, typeTeach]);

    if (effect) {
        setEffect(false);
        handleDateRangeChange([firstMonth, lastMonth]);
    }

    useEffect(() => {
        fetchTeachHour();
    }, [fetchTeachHour])

    useEffect(() => {
        sendDataTable();
    }, [sendDataTable])

    const handleSearch = debounce((inputValue) => {
        setSearch(inputValue);
    }, 1000);

    const handleSearchChange = (e) => {
        const inputValue = e.target.value;
        handleSearch(inputValue);
    };

    const renderDateRangePicker = () => {
        switch (type) {
            case 'month': {
                return (
                    <RangePicker value={[dayjs(startDate, monthFormat), dayjs(endDate, monthFormat)]}
                                 format="MM-YYYY"
                        className="mr-6 w-6/12 learning"
                        onChange={handleDateRangeChange}
                        picker="month"
                        style={{width: '100%'}}
                        disabledDate={disabledMonth}
                    />
                );
            }
            case 'quarter': {
                return <RangePicker className="mr-6 w-6/12 learning" picker="quarter" style={{width: '100%'}}
                                    value={[dayjs(startDate, monthFormat), dayjs(endDate, monthFormat)]}
                                    disabledDate={disabledMonth}
                                    onChange={handleDateRangeChange}/>;
            }
            default:
                return null;
        }
    };

    const renderDatePicker = () => {
        switch (typeTeach) {
            case 'month': {
                return (
                    <DatePicker value={dayjs(date, monthFormat)}
                                format="MM-YYYY"
                                 className="mr-6 w-6/12"
                                 onChange={handleDateChange}
                                 picker="month"
                                 style={{width: '100%'}}
                                 disabledDate={disabledMonth}
                    />
                );
            }
            case 'quarter': {
                return <DatePicker className="mr-6 w-6/12" picker="quarter" style={{width: '100%'}}
                                    value={dayjs(date, monthFormat)}
                                    disabledDate={disabledMonth}
                                    onChange={handleDateChange}/>;
            }
            default:
                return null;
        }
    };

    const handleRadioChange = (event) => {
        setType(event.target.value);
        setDataSource([]);
        setTotalLearningHours([])
        if (event.target.value === 'month') {
            setStartDate(firstMonth);
            setEndDate(lastMonth);
        } else {
            let start = new Date();
            start.setMonth(dayjs(start).startOf('quarter').month() - 15);
            setStartDate(dayjs(start).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z');
            setEndDate(lastMonth);
        }
    };

    const handleRadioTeachChange = (event) => {
        setTypeTeach(event.target.value);
        setTeachList([]);
        if (event.target.value === 'month') {
            setDate(firstMonth);
        } else {
            let date = new Date();
            const quarter = Math.floor((date.getMonth() / 3));
            const startFullQuarter = new Date(date.getFullYear(), quarter * 3, 1);
            setDate(dayjs(startFullQuarter).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z');
        }
    };

    const timePeriods = dataSource && dataSource.length > 0 ? dataSource[0].timePeriods : [];
    const lang = localStorage.getItem('i18nextLng');
    const dynamicColumns = timePeriods.map((timePeriod, index) => {
        let title;
        if (type === 'month') {
            const value = renderTitleTable[index];
            const monthString = value.substring(0, value.indexOf(' '));
            const date = new Date(`${monthString} 1, 2000`);
            let monthNumber = date.getMonth() + 1;
            const formatter = new Intl.DateTimeFormat('en', {month: 'short'});
            title = lang === 'vi' ? `T${monthNumber}` : formatter.format(date)
        } else if (type === 'quarter') {
            title = renderTitleTable[index];
        }
        return {
            title: () => {
                return (
                    <p className="text-center">{title}</p>
                )
            },
            dataIndex: `timePeriods[${index}].learningHour`,
            key: `${index}`,
            width: '70px',
            showSorterTooltip: false,
            sorter: (a, b) => {
                return a.timePeriods[index].learningHour - b.timePeriods[index].learningHour
            },
            render: (text, record) => {
                const learningHour = round(record.timePeriods[index].learningHour);

                function isLess(learningHour, kpi) {
                    return parseFloat(learningHour) < parseFloat(kpi);
                }

                return (
                    <>
                        <div className="flex justify-center">
                            {selectedValue === 'individual' ?
                                <HtmlTooltip title={<React.Fragment>
                                    <p>{t('kpiHour')}: <strong>{record.timePeriods[index].kpi}</strong></p>
                                </React.Fragment>} placement="top">
                                    <p className={`text-center max-w-[60px] w-full
                                ${record.timePeriods[index].kpi && isLess(record.timePeriods[index].learningHour, record.timePeriods[index].kpi) ? 'border border-[#D61D28] text-[#D61D28] rounded-md' : ''}`}>
                                        {learningHour}
                                    </p>
                                </HtmlTooltip>
                                :
                                <p className={`text-center max-w-[60px] w-full`}>
                                    {learningHour}
                                </p>}
                        </div>

                    </>
                );
            }
        }
    });

    function goToProfile(record) {
        navigate(`/profile/${record.id}`)
    }

    const columns = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            width: '200px',
            fixed: 'left',
            render: (text, record) => {
                const username = text.split('@')[0];
                return <p className="hover:font-bold hover:underline hover:underline-offset-1 cursor-pointer"
                    onClick={() => goToProfile(record)}>{username}</p>;
            },
        },
        ...dynamicColumns,
    ];

    const handleSelectChange = (event) => {
        setLoading(false)
        const value = event.target.value
        setSelectedValue(value);
        setDataSource([])
    };

    const handleExport = async () => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const url = `${process.env.REACT_APP_BASE_URL}/export`;
        const requestData = {
            from_date: startDate,
            to_date: endDate,
            option: selectedValue,
            type: type,
            name: search
        };
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${storedAccessToken}`
                },
                body: JSON.stringify(requestData)
            });
            const data = await response.json();
            const fileId = data.data.id;
            const downloadUrl = `${process.env.REACT_APP_BASE_URL}/assets/${fileId}?download`;
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.target = '_blank';
            link.style.display = 'none';
            document.body.appendChild(link);
            setTimeout(function () {
                link.click();
                document.body.removeChild(link);
            }, 500);
        } catch (error) {
            console.error(error);
        }
    };

    return (<>
            <HomePage handleSignOut={props.handleSignOut}/>
            <div className="px-[5%] mx-auto pt-[54px] bg-[#F5F5F5]">
                <CardInfo/>
                <div className="flex pt-10">
                    <div className="w-6/12 mr-6">
                        <div className="flex items-center">
                            <p className="text-left flex-grow font-bold">{t("learningHoursStatistics")}</p>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue="month"
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel
                                        value="month"
                                        control={<Radio style={{color: '#F0C528'}}/>}
                                        label={t('month')}
                                    />
                                    <FormControlLabel
                                        value="quarter"
                                        control={<Radio style={{color: '#F0C528'}}/>}
                                        label={t('quarter')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="rounded-lg p-4 justify-between bg-white">
                            <div className="flex pb-4">
                                {renderDateRangePicker()}
                                <FormControl fullWidth>
                                    <Select
                                        value={selectedValue}
                                        size="small"
                                        onChange={handleSelectChange}
                                        className="text-left"
                                    >
                                        <MenuItem value="individual">{t("individual")}</MenuItem>
                                        <MenuItem value="team">{t("team")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={` mx-auto w-full`}>
                                <div className="relative flex">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                                        <SearchIcon className="text-gray-400"/>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder={t("search")}
                                        className="bg-white border-solid border-[#D5D5D5] border rounded-lg w-full h-[52px] pl-10 pr-3 text-left"
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div className="rounded-lg p-4 mt-4 bg-white h-full"
                                 style={{boxShadow: "0px 0px 8px rgba(51, 51, 51, 0.1)"}}>
                                {loading ? (
                                    <div className="text-center h-full">
                                        <Spin size="large"/>
                                    </div>
                                ) : dataSource?.length === 0 ? (
                                    <div className="text-center h-full">
                                        <p>{t('noData')}</p>
                                    </div>
                                ) : (
                                    <div className="study-dashboard">
                                        <Table
                                            columns={columns}
                                            dataSource={dataSource}
                                            pagination={false}
                                            bordered
                                            scroll={{y: 690}}
                                            summary={(pageData) => {
                                                return (
                                                    <>
                                                        <Table.Summary.Row style={{fontWeight: 'bold'}}>
                                                            <Table.Summary.Cell className="ant-table-cell-fix-left ant-table-cell-fix-left-last left-0">
                                                                <p>{t('totalHour')}</p>
                                                            </Table.Summary.Cell>
                                                            {totalLearningHours.length === 0 ? (
                                                                <Table.Summary.Cell
                                                                    colSpan={columns.length - 1}>
                                                                    <Text>{t('noTotal')}</Text>
                                                                </Table.Summary.Cell>
                                                            ) : (
                                                                totalLearningHours.map((record, index) => (
                                                                    <Table.Summary.Cell key={index}>
                                                                        <p className='text-center'>{round(record.learningHour)}</p>
                                                                    </Table.Summary.Cell>
                                                                ))
                                                            )}
                                                        </Table.Summary.Row>
                                                    </>
                                                );
                                            }}
                                            className="sticky-summary pb-8"
                                        />
                                        {checkHR() && <div className="flex justify-end">
                                            <button
                                                className="rounded-xl border-2 border-yellow-500 px-4 py-2 rounded text-yellow-500 hover:bg-yellow-500 hover:text-white transition-colors duration-300 ease-in-out cursor-pointer"
                                                onClick={handleExport}>
                                                {t('export')}
                                            </button>
                                        </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-6/12">
                        <div className="flex items-center">
                            <p className="text-left flex-grow font-bold">{t('teachTime')}</p>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue="month"
                                    onChange={handleRadioTeachChange}
                                >
                                    <FormControlLabel
                                        value="month"
                                        control={<Radio style={{color: '#F0C528'}}/>}
                                        label={t('month')}
                                    />
                                    <FormControlLabel
                                        value="quarter"
                                        control={<Radio style={{color: '#F0C528'}}/>}
                                        label={t('quarter')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="rounded-lg p-4 bg-white overflow-y-auto h-[510px]">
                            <div className="flex pb-4 w-[50%]">
                                {renderDatePicker()}
                            </div>

                            <div className="flex justify-between relative">
                                <div className="left-0"><strong>{t('kpiHour')}</strong></div>
                                <div className="absolute w-full text-center">{Math.round(kpi / 2 * 100) / 100}</div>
                                <div className="right-0"><strong>{kpi}</strong></div>
                            </div>
                            {teachList?.map((item, index) => (
                                <Progressbar key={index} progress={item.kpi === 0 ? '100' : item.teaching_time / item.kpi * 100}
                                             first={index === 0} isOdd={index % 2 === 0} user={item}/>
                            ))}
                        </div>
                        <FeaturedCourse/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Statistical;
