import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {toast} from "react-toastify";
import api from "../axios";
import {DialogActions} from "@mui/material";
import './profile.css';
import {useTranslation} from "react-i18next";

const AddCourseDialog = ({open, setOpen, reloadCourse}) => {
    const {t} = useTranslation();
    const [courseInfo, setCourseInfo] = useState({image:'', title: '', link: '', description: ''});
    const [errors, setErrors] = useState({});

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        // Create a form data object to send the file
        const formData = new FormData();
        formData.append('file', file);

        await api.post(`${process.env.REACT_APP_BASE_URL}/files`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(response => {
                const imageUrl = response.data.data.id;
                let data = {
                    ...courseInfo,
                    image: imageUrl
                };
                setCourseInfo(data);
                handleValidation('image', data)
            }).catch(error => {
                toast.error(error.response.data.errors[0].message)
            });
    };

    function uploadFile() {
        document.getElementById("imageCourse").click();
    }

    function addCourse() {
        if (!handleValidation()) return;
        let url = `${process.env.REACT_APP_BASE_URL}/items/course`;
        let data = {
            description: courseInfo.description,
            title: courseInfo.title,
            image: courseInfo.image,
            link: courseInfo.link,
            category_id: 7,
        }
        api.post(url, data)
            .then(response => {
                toast.success(t('addCourseSuccess'));
                setOpen(false);
                reloadCourse();
            }).catch(error => {
            console.log(error);
                toast.error(error.response.data.message)
            });
    }

    function handleValidation(name, data) {
        let error = errors ? {...errors} : {};
        data = data ? data : courseInfo;
        let formIsValid = true;
        if (!name || name === 'image') {
            if (!data.image) {
                formIsValid = false;
                error["image"] = t('emptyError', {field: t('imageCourse')});
            } else {
                error["image"] = '';
            }
        }

        if (!name || name === 'title') {
            if (!data.title) {
                formIsValid = false;
                error["title"] = t('emptyError', {field: t('titleCourse')});
            } else {
                error["title"] = '';
            }
        }

        if (!name || name === 'description') {
            if (!data.description) {
                formIsValid = false;
                error["description"] = t('emptyError', {field: t('descriptionCourse')});
            } else {
                error["description"] = '';
            }
        }

        setErrors(error);
        return formIsValid;
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let data = {
            ...courseInfo,
            [name]: value
        }
        setCourseInfo(data);
        handleValidation(name, data);
    }

    return <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
    >
        <DialogTitle>
            <div className="flex justify-between border-bottom">
                <p>{t('addCourse')}</p>
                <CloseIcon className="cursor-pointer" onClick={() => setOpen(false)}/>
            </div>
        </DialogTitle>
        <DialogContent>
            <div className="flex pt-[8px] px-[24px] mx-auto flex justify-between bg-[#F6F6F6]">
                <div className="h-full">
                    <label className="required">{t('imageCourse')}</label>
                    {courseInfo.image ?
                        <div className="profile-pic rounded-2xl w-[295px] h-[295px]">
                            <label className="-label" htmlFor="imageCourse"
                                   style={{width: '100%', height: '100%'}}>
                                <span className="glyphicon glyphicon-camera"></span>
                                <span>{t('changeImage')}</span>
                            </label>
                            <input id="imageCourse" type="file" onChange={handleFileUpload}/>
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/assets/${courseInfo.image}`}
                                style={{width: '100%', height: '100%'}}
                                alt=""
                            />
                        </div>
                    :
                        <div className="flex justify-center items-center rounded-2xl border border-dashed w-[295px] h-[295px] cursor-pointer hover:bg-[#FFFFFF]" onClick={uploadFile}>
                            <div>
                                <div className="flex justify-center">
                                    <img src="/Images/image-empty.svg" width='20px' alt=''/>
                                </div>
                                <span className="text-[#979696]">{t('uploadImage')}</span>
                            </div>
                            <input id="imageCourse" type="file" onChange={handleFileUpload} hidden={true}/>
                        </div>}
                    <span style={{ color: "red" }}>{errors["image"]}</span>
                </div>
                <div className="w-full pl-8">
                    <form>
                        <label className="required">{t('titleCourse')}</label>
                        <input
                            name="title"
                            placeholder={t('titleCoursePlace')}
                            value={courseInfo?.title}
                            onChange={handleInputChange} />
                        <span style={{ color: "red" }}>{errors["title"]}</span>
                        <br/>
                        <label>
                            {t('linkCourse')}
                        </label>
                        <input
                            name="link"
                            placeholder={t('linkCoursePlace')}
                            value={courseInfo?.link}
                            onChange={handleInputChange} />
                        <span style={{ color: "red" }}>{errors["link"]}</span>
                        <br/>
                        <label className="required">
                            {t('descriptionCourse')}
                        </label>
                        <textarea
                            className="required"
                            name="description"
                            placeholder={t('descriptionCoursePlace')}
                            value={courseInfo?.description}
                            rows={6}
                            onChange={handleInputChange} />
                        <span style={{ color: "red" }}>{errors["description"]}</span>
                    </form>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <div className="px-[8px] pb-4">
                <button className="bg-[#F0C528] text-[#000000] border-[0px] rounded-md"
                        onClick={addCourse}>{t('add')}</button>
            </div>
        </DialogActions>
    </Dialog>
}

export default AddCourseDialog;
