import axios from 'axios'
import globalRouter from "./globalRouter";
import {handleClose} from "./WebSocket/LogOutEvent";
import i18next from "i18next";
import wsRef from "./WebSocket/wsRef";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g., parse, transform)

        return response;
    },
    async (error) => {
        // Handle response errors here
        if (error.response.status === 401) {
            await handleSignOut();
        } else {
            throw error;
        }
    }
);

const handleSignOut = async () => {
    const storedAccessToken = localStorage.getItem('accessToken');

    function logout() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userID");
        localStorage.removeItem("role");
        localStorage.setItem("loggedIn", true);
        localStorage.setItem('QA', "logOut");
        globalRouter.navigate("/");
        handleClose()
    }

    if (storedAccessToken) {
        function refreshTokens() {
            return fetch(process.env.REACT_APP_BASE_URL + '/auth/refresh', {
                method: 'POST',
                credentials: 'include'
            });
        }
        const handleConnect = (accessToken) => {
            const url = process.env.REACT_APP_SOCKET + (accessToken ? '?access_token=' + accessToken : '');
            wsRef.current = new WebSocket(url);
        }
        refreshTokens().then(response => response.json())
            .then(data => {
                const language = ['vi', 'en'];
                const accessToken = data?.data?.access_token;
                if (!accessToken) {
                    logout();
                    return;
                }
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('QA', "active");
                if (!language.includes(localStorage.getItem('i18nextLng'))) {
                    localStorage.setItem('i18nextLng', language[0]);
                    i18next.changeLanguage(language[0]);
                }
                globalRouter.navigate("/home");
                handleConnect(accessToken);
            }).catch( (error) => {
                logout();
        })

    }
};

export default api;
