import React from 'react'
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {styled, tooltipClasses} from "@mui/material";

const Progressbar = ({progress, first, isOdd, user}) => {
    const {t} = useTranslation();
    const Parentdiv = {
        height: '69px',
        width: '100%',
        borderTop: first ? 'solid 1px #D5D5D5' : '',
        borderBottom: 'dashed 1px #D5D5D5',
    }

    const Childdiv = {
        position: 'absolute',
        height: '100%',
        left: progress >= 100 ? '100%' : `${progress}%`,
        zIndex: 100
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#2F2E2E',
            color: 'white',
            maxWidth: 130,
            border: '1px solid #2F2E2E',
            borderRadius: '8px'
        },
    }));

    return (
        <div style={Parentdiv} className="relative">
            <div className="flex relative w-[91%] h-full">
            <div style={Childdiv} className="flex items-center justify-end">
                <HtmlTooltip title={<React.Fragment>
                    <p><strong style={{textTransform: 'capitalize'}}>{user.email.split("@")[0]}</strong></p>
                    <p>{t('teachTime')}: <strong>{user.teaching_time}</strong></p>
                </React.Fragment>} placement="top">
                    <div className="h-[40px] rounded-lg flex justify-start bg-[#0FBDA1] p-[4px] w-[70px]">
                        <span className="mr-[4px]">{user.teaching_time}h</span>
                        <img src={`${process.env.REACT_APP_BASE_URL}/assets/${user.avatar}`} alt=""
                             className="w-[32px] h-[32px]" style={{borderRadius: '4px'}}/>
                    </div>
                </HtmlTooltip>
            </div>
            </div>
            {isOdd ? <img className="absolute right-0 top-[-1px]" src="/Images/finish.svg" alt=''/>
                :
                <img className="absolute right-0 top-[-1px]" src="/Images/finish-2.svg" alt=''/>}
            <div className="absolute border-left top-0 left-[50%] h-full border-left-[#D5D5D5]"></div>
        </div>
    )
}

export default Progressbar;
