import React, {useEffect, useRef, useState} from "react";
import HomePage from "../HomePage/homePage";
import "../App.css"
import axios from 'axios';
import {useTranslation} from "react-i18next";
import {Progress} from 'antd';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate, useParams} from "react-router-dom"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import api from "../axios";
import CourseListDialog from "./CourseListDialog";
import AddCourseDialog from "./AddCourseDialog";
import ConfirmDialog from "../common/ConfirmDialog";
import {toast} from "react-toastify";
import dayjs from "dayjs";

const noiticeIcon = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.99992 11.8335C8.18881 11.8335 8.34725 11.7695 8.47525 11.6415C8.60325 11.5135 8.66703 11.3553 8.66659 11.1668V8.50016C8.66659 8.31127 8.60259 8.15283 8.47459 8.02483C8.34659 7.89683 8.18836 7.83305 7.99992 7.8335C7.81103 7.8335 7.65259 7.8975 7.52459 8.0255C7.39659 8.1535 7.33281 8.31172 7.33325 8.50016V11.1668C7.33325 11.3557 7.39725 11.5142 7.52525 11.6422C7.65325 11.7702 7.81147 11.8339 7.99992 11.8335ZM7.99992 6.50016C8.18881 6.50016 8.34725 6.43616 8.47525 6.30816C8.60325 6.18016 8.66703 6.02194 8.66659 5.8335C8.66659 5.64461 8.60259 5.48616 8.47459 5.35816C8.34659 5.23016 8.18836 5.16639 7.99992 5.16683C7.81103 5.16683 7.65259 5.23083 7.52459 5.35883C7.39659 5.48683 7.33281 5.64505 7.33325 5.8335C7.33325 6.02239 7.39725 6.18083 7.52525 6.30883C7.65325 6.43683 7.81147 6.50061 7.99992 6.50016ZM7.99992 15.1668C7.0777 15.1668 6.21103 14.9917 5.39992 14.6415C4.58881 14.2913 3.88325 13.8164 3.28325 13.2168C2.68325 12.6168 2.20836 11.9113 1.85859 11.1002C1.50881 10.2891 1.3337 9.42239 1.33325 8.50016C1.33325 7.57794 1.50836 6.71127 1.85859 5.90016C2.20881 5.08905 2.6837 4.3835 3.28325 3.7835C3.88325 3.1835 4.58881 2.70861 5.39992 2.35883C6.21103 2.00905 7.0777 1.83394 7.99992 1.8335C8.92214 1.8335 9.78881 2.00861 10.5999 2.35883C11.411 2.70905 12.1166 3.18394 12.7166 3.7835C13.3166 4.3835 13.7917 5.08905 14.1419 5.90016C14.4921 6.71127 14.667 7.57794 14.6666 8.50016C14.6666 9.42239 14.4915 10.2891 14.1413 11.1002C13.791 11.9113 13.3161 12.6168 12.7166 13.2168C12.1166 13.8168 11.411 14.2919 10.5999 14.6422C9.78881 14.9924 8.92214 15.1673 7.99992 15.1668ZM7.99992 13.8335C9.48881 13.8335 10.7499 13.3168 11.7833 12.2835C12.8166 11.2502 13.3333 9.98905 13.3333 8.50016C13.3333 7.01127 12.8166 5.75016 11.7833 4.71683C10.7499 3.6835 9.48881 3.16683 7.99992 3.16683C6.51103 3.16683 5.24992 3.6835 4.21659 4.71683C3.18325 5.75016 2.66659 7.01127 2.66659 8.50016C2.66659 9.98905 3.18325 11.2502 4.21659 12.2835C5.24992 13.3168 6.51103 13.8335 7.99992 13.8335Z"
        fill="#979696"/>
</svg>

const Profile = (props) => {
    const navigate = useNavigate()
    const {id} = useParams()
    const {t} = useTranslation()
    const [profileDetails, setProfileDetails] = useState(null);
    const storedAccessToken = localStorage.getItem('accessToken');
    const [courseData, setCourseData] = useState([])
    const [totalLearningHour, setTotalLearningHour] = useState(null)
    const [rankDetails, setRankDetails] = useState("")
    const [courseStatistics, setCourseStatistics] = useState(null)
    const [effect, setEffect] = useState(true);
    const [courses, setCourses] = useState([]);
    const [openCourseDialog, setOpenCourseDialog] = useState(false);
    const [openAddCourseDialog, setOpenAddCourseDialog] = useState(false);
    const [personalCourse, setPersonalCourse] = useState([]);
    const [schedulePlan, setSchedulePlan] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const scrollableRef = useRef(null);
    const [scheduleCourse, setScheduleCourse] = useState([]);
    const [openScheduleDeleteDialog, setOpenScheduleDeleteDialog] = useState(false);
    const [openPersonalDeleteDialog, setOpenPersonalDeleteDialog] = useState(false);
    const [deleteCourse, setDeleteCourse] = useState(null);
    const [loadingCreateSchedule, setLoadingCreateSchedule] = useState(false);
    const [isFull, setIsFull] = useState(false);
    const userID = id ? id : localStorage.getItem("userID");
    const currentUser = localStorage.getItem("userID");
    const view = props.view;
    const infoData = props.infoData;

    const getProfileUser = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_BASE_URL}/users/${userID}?fields=*,team_id.*,learning_plan.*,learning_plan.courses.course_id.*`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`
                }
            });

            const data = await response.json();
            setProfileDetails(data.data)
        } catch (error) {
            console.error(error);
        }
    }
    const fetchJoinedCourse = async () => {
        api.get(`${process.env.REACT_APP_BASE_URL}/flows/trigger/df524185-f718-4c57-891d-0761aabbd03e?directus_users_id=${userID}&sort=sort,-notes.date_updated,-notes.date_created`)
            .then(response => {
                setCourses(response.data.data);
                setIsFull(response.data.data.length > 4);
            });
    };
    const fetchPersonalCourse = async () => {
        api.get(`${process.env.REACT_APP_BASE_URL}/items/course?fields=*,category_id.*&filter[category_id][code][_eq]=PERSONAL_COURSE&filter[user_created][_eq]=${userID}`)
            .then(response => {
                setPersonalCourse(response.data.data);
                setIsFull(response.data.data.length > 3);
            });
    };
    const fetchDataStatic = async () => {
        const currentYear = dayjs().year();
        const currentMonth = dayjs().month() + 1; // Months are zero-based, so we add 1
        const firstMonth = dayjs(`${currentYear}-${currentMonth}`).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        const lastMonth = dayjs(`${currentYear}-${currentMonth}`).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
        const url = `${process.env.REACT_APP_BASE_URL}/flows/trigger/22833381-b10f-45c5-a4ee-f90aa8b34f73?directus_users_id=${userID}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${storedAccessToken}`,
                },
                body: JSON.stringify({from_date: firstMonth, to_date: lastMonth}),
            });
            if (response.ok) {
                const data = await response.json();
                setCourseStatistics(data.courseStatistics)
                setCourseData(data.level)
                setRankDetails(data.level?.current_level_object)
                setTotalLearningHour(data.level?.totalLearningHour)
            } else {
                console.log('Error:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    const month = new Date().getMonth();

    useEffect(() => {
        if (scrollableRef.current && schedulePlan.length > 0) {
            const scrollableDiv = scrollableRef.current.childNodes[0];
            let i = 0;
            for (; i < schedulePlan.length; i++) {
                if (new Date(schedulePlan[i].start_date).getMonth() === month) {
                    break;
                }
            }
            if (i >= schedulePlan.length) return;
            const middleItem = scrollableDiv.childNodes[i];
            if (!middleItem) return;
            middleItem.scrollIntoView();
            middleItem.click();
        }
    }, [schedulePlan, month]);

    useEffect(() => {
        setEffect(true);
    }, [effect, infoData])

    const createSchedulePlan = async (time) => {
        let date = time ? time : new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let body = {
            directus_users_id: userID,
            start_date: firstDay,
            end_date: lastDay
        }

        await api.post(`${process.env.REACT_APP_BASE_URL}/items/learning_plan`, body)
            .then(response => fetchSchedulePlan())
            .finally(() => {
                setLoadingCreateSchedule(false);
            });
    }

    const fetchSchedulePlan = async () => {
        await api.get(`${process.env.REACT_APP_BASE_URL}/items/learning_plan?filter[directus_users_id][_eq]=${userID}&sort=start_date`)
            .then(response => {
                if (userID !== currentUser) {
                    setSchedulePlan(response.data.data);
                    return;
                }
                let plan = response.data.data;
                let isPlanThisMonth = false;
                if (plan && plan.length > 0) {
                    for (let i = 0; i < plan.length; i++) {
                        if (new Date(plan[i].start_date).getMonth() === month) {
                            isPlanThisMonth = true;
                            break
                        }
                    }
                }
                if (!isPlanThisMonth) {
                    createSchedulePlan()
                } else {
                    setSchedulePlan(response.data.data);
                }
            });
    }

    const selectPlan = (planId) => {
        setSelectedSchedule(planId);
        api.get(`${process.env.REACT_APP_BASE_URL}/items/learning_plan/${planId}?fields=*,courses.id,courses.course_id.*&filter[directus_users_id][_eq]=${userID}`)
            .then(response => {
                setScheduleCourse(response.data.data.courses)
                setIsFull(response.data.data.courses.length > 3);
            });
    }

    if (effect) {
        setEffect(false);
        getProfileUser()
        fetchDataStatic();
        fetchJoinedCourse();
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        // Create a form data object to send the file
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/files`, formData, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            const imageUrl = response.data.data.id;
            const updateResponse = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/me`, {
                avatar: imageUrl,
            }, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`,
                    "Content-Type": "application/json",
                },
            });
            if (updateResponse.status === 200) {
                const updatedProfileDetails = {...profileDetails, avatar: imageUrl};
                setProfileDetails(updatedProfileDetails);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleNoteDetails = (id, event) => {
        if (view) return;
        event.preventDefault();
        navigate(`/note/${id}`);
    };

    const percentLearnHour = (courseData?.totalLearningHour / rankDetails?.max_hour) * 100;
    const percentLearnComplete = (courseData?.course_completion / rankDetails?.max_course) * 100;

    const CourseJoined = <div
        className={`cursor-pointer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pt-4 pb-4 px-[16px] mx-auto`}>
        {courses?.map((item, index) => (
            <div
                key={index}
                className='border shadow-md rounded-lg hover:scale-105 duration-300 bg-white'
                onClick={(e) => handleNoteDetails(item.id, e)}
            >
                <div className="p-4">
                    <img
                        key={item.image}
                        src={`${process.env.REACT_APP_BASE_URL}/assets/${item.image}`}
                        alt={item?.name}
                        className='w-full rounded h-[120px] object-cover rounded-t-lg'
                    />
                </div>
                <div className='font-bold flex justify-start px-4 pb-4 text-sm text-left overflow-ellipsis'>
                    <div className="pr-1 top-0 mt-[2px] w-[20px]">{item.isCompletion && <img src="/Images/checkIcon.svg" alt=''/>}</div>
                    <div className="w-full">{item?.title}</div>
                </div>
            </div>
        ))}
    </div>;

    function createPersonalCourse() {
        if (view) return;
        setOpenAddCourseDialog(true);
    }

    const PersonalCourse = <div
        className={`cursor-pointer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pt-4 pb-4 px-[16px] mx-auto`}>
        {!view &&
            <div
                className='border border-dashed shadow-md rounded-lg hover:scale-105 duration-300 bg-white flex justify-center items-center'
                style={{minHeight: '188px'}}
                onClick={() => createPersonalCourse()}
            >
                <div>
                    <div className="flex justify-center">
                        <img src="/Images/add-circle.svg" alt=''/>
                    </div>
                    <span className="text-[#979696]">{t('createCourse')}</span>
                </div>
            </div>
        }
        {personalCourse?.map((item, index) => (
            <div
                key={index}
                className='border shadow-md group rounded-lg hover:scale-105 duration-300 bg-white'
                onClick={(e) => handleNoteDetails(item.id, e)}
            >
                <div className="p-4">
                    <img
                        key={item.image}
                        src={`${process.env.REACT_APP_BASE_URL}/assets/${item.image}`}
                        alt={item?.name}
                        className='w-full rounded h-[120px] object-cover rounded-t-lg'
                    />
                </div>
                <div className='font-bold flex justify-between px-4 pb-4 text-sm text-left overflow-ellipsis'>
                    <span>{item?.title}</span>
                    {!view &&
                        <div
                            className='cursor-pointer hidden group-hover:block bg-[#D5D5D5] w-[32px] h-[24px] border-[0px] hover:scale-125 rounded-md'
                            onClick={(event) => {
                                event.stopPropagation();
                                setDeleteCourse(item)
                                setOpenPersonalDeleteDialog(true)
                            }}>
                            <div className="flex justify-center items-center w-full h-full">
                                <img src="/Images/delete.svg" alt=""/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        ))}
    </div>

    const convertDate = (dateStr) => {
        let date = new Date(dateStr);
        return `${t('month')} ${date.getMonth() + 1}, ${date.getFullYear()}`
    }

    function handleCreateSchedulePlan() {
        if (!loadingCreateSchedule) {
            setLoadingCreateSchedule(true);
        }
        if (!schedulePlan || schedulePlan.length === 0) {
            createSchedulePlan();
            return;
        }
        let lastSchedule = schedulePlan[schedulePlan.length - 1];
        let lastDate = new Date(lastSchedule.start_date);
        lastDate.setMonth(lastDate.getMonth() + 1);
        createSchedulePlan(lastDate);
    }

    const ScheduleCourse = <div>
        <div className="flex justify-between h-[47px] px-[16px] mt-4 mb-4">
            <div className="overflow-x-auto flex mr-[8px]" ref={scrollableRef}
                 style={{maxWidth: 'calc(100vw - 64px'}}>
                <div
                    style={{flex: 'none'}}>
                    {schedulePlan?.map((item, index) => (
                        <button key={index}
                                className={`w-[160px] h-full border border-[#D5D5D5] justify-center items-center rounded-md mr-[8px] hover:bg-[#2F2E2E] hover:text-[#F0C528]
                                    ${selectedSchedule === item.id ? "bg-[#2F2E2E] text-[#F0C528]" : ""}`}
                                onClick={() => selectPlan(item.id)}>
                            {convertDate(item.start_date)}
                        </button>
                    ))}
                </div>
            </div>
            {!view &&
                <button onClick={handleCreateSchedulePlan} disabled={loadingCreateSchedule}
                        className="w-[64px] border border-dashed rounded-md border-[#D5D5D5] flex justify-center items-center">
                    {loadingCreateSchedule ? <span className="loader" style={{width: '16px', height: '16px'}}></span> : <img src="/Images/add-circle.svg" alt=""/>}
                </button>
            }
        </div>
        <div
            className={`cursor-pointer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pt-4 pb-4 px-[16px] mx-auto`}>
            {!view && <div
                className='border border-dashed shadow-md rounded-lg hover:scale-105 duration-300 bg-white flex justify-center items-center'
                style={{minHeight: '188px'}}
                onClick={() => setOpenCourseDialog(true)}>
                <div>
                    <div className="flex justify-center">
                        <img src="/Images/add-circle.svg" alt=''/>
                    </div>
                    <span className="text-[#979696]">{t('addCourse')}</span>
                </div>
            </div>
            }
            {scheduleCourse?.map((item, index) => (
                <div
                    key={index}
                    className='border shadow-md group rounded-lg hover:scale-105 duration-300 bg-white'
                    style={{minHeight: '188px'}}
                    onClick={(e) => handleNoteDetails(item.course_id?.id, e)}
                >
                    <div className="p-4">
                        <img
                            key={item.course_id?.image}
                            src={`${process.env.REACT_APP_BASE_URL}/assets/${item.course_id?.image}`}
                            alt={item?.course_id?.name}
                            className='w-full rounded h-[120px] object-cover rounded-t-lg'
                        />
                    </div>
                    <div className='font-bold flex justify-between px-4 pb-4 text-sm text-left overflow-ellipsis'>
                        <span>{item?.course_id?.title}</span>
                        {!view &&
                            <div
                                className='cursor-pointer hidden group-hover:block bg-[#D5D5D5] w-[32px] h-[24px] border-[0px] hover:scale-125 rounded-md'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setDeleteCourse(item)
                                    setOpenScheduleDeleteDialog(true)
                                }}>
                                <div className="flex justify-center items-center w-full h-full">
                                    <img src="/Images/delete.svg" alt=""/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    </div>

    let confirmDeleteScheduleCourse = () => {
        let body = {
            courses: {
                create: [],
                update: [],
                delete: [deleteCourse.id]
            }
        }

        api.patch(`${process.env.REACT_APP_BASE_URL}/items/learning_plan/${selectedSchedule}`, body)
            .then(() => {
                toast.success(t('deleteCourseSuccess'));
                setOpenScheduleDeleteDialog(false);
                selectPlan(selectedSchedule);
            }).catch(error => {
            toast.error(error.response.data.errors[0].message)
        })
    };
    let confirmDeletePersonalCourse = () => {
        api.delete(`${process.env.REACT_APP_BASE_URL}/items/course/${deleteCourse.id}`)
            .then(() => {
                toast.success(t('deleteCourseSuccess'));
                setOpenPersonalDeleteDialog(false);
                fetchPersonalCourse()
            }).catch(error => {
            toast.error(error.response.data.errors[0].message)
        })
    };
    return (
        <>
            <HomePage handleSignOut={props.handleSignOut}/>
            <div className="flex pt-[54px] px-[5%] mx-auto flex justify-start bg-[#F6F6F6]">
                <div className="rounded-2xl p-4 bg-white h-fit"
                     style={{boxShadow: '0px 8px 18px rgba(46, 45, 40, 0.08)'}}>
                    {/* Profile Pic */}
                    <div className="bg-[#F6F6F6] p-4 rounded-2xl"
                         style={{boxShadow: "0px 0px 8px rgba(51, 51, 51, 0.1)"}}>
                        <div className="flex items-center justify-center pb-4">
                            <div className="profile-pic px-[50px] pt-[38px]">
                                {!view ? <>
                                    <label className="-label" htmlFor="file">
                                        <span className="glyphicon glyphicon-camera"></span>
                                        <span>Change Image</span>
                                    </label>
                                    <input id="file" type="file" onChange={handleFileUpload}/>
                                    {profileDetails && (
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/assets/${profileDetails?.avatar}`}
                                            id="output"
                                            width="500"
                                            alt="Profile"
                                        />
                                    )}
                                </>
                                :
                                    profileDetails && (
                                        <img style={{position: 'relative'}}
                                            src={`${process.env.REACT_APP_BASE_URL}/assets/${profileDetails?.avatar}`}
                                            id="output"
                                            width="500"
                                            alt="Profile"
                                        />
                                    )}

                            </div>
                        </div>
                        {/*///////// Profile Details*/}
                        <div className="tex-[#979696] text-xs pb-2"> {profileDetails?.email}</div>
                        <div
                            className="text-xl font-bold">
                            {profileDetails?.last_name} {profileDetails?.first_name}
                        </div>
                        <span className="text-xs pt-2"> {profileDetails?.position}</span>
                        <span className="mx-[5%] text-[#979696]">|</span>
                        <span className="text-xs text-[#979696]">{profileDetails?.team_id?.name}</span>
                    </div>
                    <div className="pt-11">
                        <div className="text-left text-sm font-bold pb-4">
                            {rankDetails?.name}
                        </div>
                        <div className="flex items-center justify-center">
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/assets/${rankDetails?.image}`}
                                id="output"
                                width="130"
                                alt="RankAvatar"
                            />
                        </div>
                    </div>
                    <div className="pt-4">
                        <div className="flex items-center justify-between">
                            <div className="text-left text-sm">{t("numberOfHours")}</div>
                            <div
                                className="text-sm text-right text-[#979696]">{totalLearningHour}/{rankDetails?.max_hour}</div>
                        </div>
                        <Progress percent={percentLearnHour} showInfo={false} status="active" size={[300, 20]}
                                  strokeColor={{from: '#F0C528', to: '#E86F2B'}}/>
                    </div>
                    <div className="pt-4">
                        <div className="flex items-center justify-between">
                            <div className="text-left text-sm">{t("numberCoursesCompleted")}</div>
                            <div className="text-sm text-right text-[#979696]">{courseData?.course_completion}/{rankDetails?.max_course}</div>
                        </div>
                        <Progress percent={percentLearnComplete} showInfo={false} status="active" size={[300, 20]}
                                  strokeColor={{from: '#2DFF90', to: '#0FA958'}}/>
                    </div>
                </div>
                {/*card*/}
                <div className="w-full pl-[62px] flex flex-col"
                     style={{height: isFull ? '100%' : 'calc(100vh - 210px'}}>
                    <div className="grid gap-[24px] md:grid-cols-3"
                         style={{flex: '0 1 auto'}}>
                        <div
                            className="max-w-full rounded-2xl overflow-hidden border border-solid shadow-sm bg-[#FFFFFF]"
                            style={{boxShadow: '0px 0px 8px rgba(46, 45, 40, 0.1)'}}>
                            <div className="p-[16px] flex-1">
                                <div className="flex justify-between">
                                    <div className="font-normal text-sm mb-2 text-left pr-1">{t("learningMonth")}</div>
                                    <Tooltip title={t("learningKpiTooltip")} placement="top">
                                        {noiticeIcon}
                                    </Tooltip>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-[24px] font-semibold" style={{marginRight: '10px'}}>
                                        {courseStatistics?.learningHourMonth}/{courseStatistics?.kpiLearning}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*card2*/}
                        <div
                            className="max-w-full rounded-2xl overflow-hidden border border-solid shadow-sm bg-[#FFFFFF]"
                            style={{boxShadow: '0px 0px 8px rgba(46, 45, 40, 0.1)'}}>
                            <div className="p-[16px] flex-1">
                                <div className="flex justify-between">
                                    <div className="font-normal text-sm mb-2 text-left pr-1">{t("trainingMonth")}</div>
                                    <Tooltip title={t("trainingKpiTooltip")} placement="top">
                                        {noiticeIcon}
                                    </Tooltip>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-[24px] font-semibold" style={{marginRight: '10px'}}>
                                        {courseStatistics?.teachingHourMonth}/{courseStatistics?.kpiTraining}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*card3*/}
                        <div
                            className="max-w-full rounded-2xl overflow-hidden border border-solid shadow-sm bg-[#FFFFFF]"
                            style={{boxShadow: '0px 0px 8px rgba(46, 45, 40, 0.1)'}}>
                            <div className="p-[16px] flex-1">
                                <div className="flex justify-between">
                                    <div
                                        className="font-normal text-sm mb-2 text-left pr-1">{t("totalLearningTime")}</div>
                                    <Tooltip title={t("totalLearningTooltip")} placement="top">
                                        {noiticeIcon}
                                    </Tooltip>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-[24px] font-semibold" style={{marginRight: '10px'}}>
                                        {courseStatistics?.totalLearningHour}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-[37px]"
                         style={{flex: '1 1 auto'}}>
                        <Tabs defaultIndex={0}>
                            <TabList>
                                <Tab onClick={fetchJoinedCourse}>
                                    <div className="w-[200px] px-5%">{t('courseJoined')}</div>
                                </Tab>
                                <Tab onClick={fetchPersonalCourse}>
                                    <div className="w-[200px] px-5%">{t('personalCourse')}</div>
                                </Tab>
                                <Tab onClick={fetchSchedulePlan}>
                                    <div className="w-[200px] px-5%">{t('scheduleCourse')}</div>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <div className={`shadow-md border shadow-md duration-300 bg-white h-full`}
                                     style={{borderRadius: '0 8px 8px 8px'}}>
                                    {CourseJoined}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className={`shadow-md border shadow-md duration-300 bg-white h-full`}
                                     style={{borderRadius: '8px'}}>
                                    {PersonalCourse}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className={`shadow-md border shadow-md duration-300 bg-white h-full`}
                                     style={{borderRadius: '8px'}}>
                                    {ScheduleCourse}
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>

            {openCourseDialog && <CourseListDialog open={openCourseDialog}
                                                   setOpen={setOpenCourseDialog}
                                                   scheduleId={selectedSchedule}
                                                   reloadSchedule={selectPlan}
                                                   scheduleCourse={scheduleCourse}
            />}

            {openAddCourseDialog && <AddCourseDialog open={openAddCourseDialog} setOpen={setOpenAddCourseDialog}
                                                     reloadCourse={fetchPersonalCourse}/>}

            {openScheduleDeleteDialog && <ConfirmDialog open={openScheduleDeleteDialog} setOpen={setOpenScheduleDeleteDialog}
                                                        onConfirm={confirmDeleteScheduleCourse}
                                                        content={t("confirmDelScheduleCourse")}/>}

            {openPersonalDeleteDialog && <ConfirmDialog open={openPersonalDeleteDialog} setOpen={setOpenPersonalDeleteDialog}
                                                        onConfirm={confirmDeletePersonalCourse}
                                                        content={t("confirmDelPersonalCourse")}/>}
        </>)
}
export default Profile;
