import React, {useEffect, useState} from "react"
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const FeaturedCourse = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/flows/trigger/d03f7d11-8dec-4099-bd94-730a87995d5f?limit=5`,
                    {
                        headers: {
                            Authorization: `Bearer ${storedAccessToken}`,
                        },
                    }
                );
                setCourses(response.data.data);
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        fetchData();
    }, []);

    let gotoCourse = (id) => {
        navigate(`/note/${id}`);
    };
    return (<>
        <div className="flex items-center pt-2 pb-2">
            <p className="text-left flex-grow font-bold">{t("featuredCourse")}</p>
        </div>
        <div className="rounded-lg bg-white" style={{boxShadow: "0px 0px 8px rgba(51, 51, 51, 0.1)"}}>
            {courses.map((course) => (
                <div key={course.id} className="p-4 cursor-pointer border-b hover:bg-gray-200" onClick={() => gotoCourse(course.id)}>
                    <div className="flex">
                        <img src={`${process.env.REACT_APP_BASE_URL}/assets/${course.image}`} alt=""
                             className="w-[56px] h-[56px] rounded object-cover"/>
                        {courses.image}
                        <div className="pl-2">
                            <p className="text-left text-lg font-bold">{course.title}</p>
                            <p className="lowercase text-left text-[#979696] text-base">{course.userCount} {t("learner")}</p>
                        </div>
                    </div>
                </div>
            ))
            }
        </div>
    </>)
}
export default FeaturedCourse
