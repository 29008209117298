import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React, {useCallback, useEffect, useRef, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import api from "../axios";
import debounce from "lodash/debounce";
import {DialogActions} from "@mui/material";
import {toast} from "react-toastify";

const CourseListDialog = ({open, setOpen, scheduleId, reloadSchedule, scheduleCourse}) => {
    const {t} = useTranslation()
    const [storedAccessToken, setStoredAccessToken] = useState(null);
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        setStoredAccessToken(accessToken);
    }, []);
    const LIMIT_DATA = 12;
    const [dataSource, setDataSource] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(true);
    const scrollableRef = useRef(null);
    const [courseChoose, setCourseChoose] = useState([]);

    const fetchData = useCallback(
        async (searchQuery = '', reset = false) => {
            let data = dataSource;
            let loadingMore = isLoadingMore;
            if (reset) {
                data = [];
                loadingMore = true;
                setIsLoadingMore(loadingMore);
            }
            if (!loadingMore) {
                return;
            }
            try {
                const payload = {
                    title: searchQuery,
                    category_id: [0],
                    exclude_id: scheduleCourse ? scheduleCourse.map(item => item.course_id.id) : []
                };
                const config = {
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${storedAccessToken}`
                    }
                };
                setIsLoading(true);
                let paging = data.length === 0 ? 1 : (Math.floor(data.length / LIMIT_DATA) + 1);
                const response = await api.post(
                    `${process.env.REACT_APP_BASE_URL}/flows/trigger/6871f313-a5e3-4edd-917b-6217451e01b9?page=${paging}&limit=${LIMIT_DATA}&sort=sort&sort=-id`,
                    payload,
                    config
                );
                data.push(...response.data.data)
                if (data.length >= response.data.total_count) {
                    setIsLoadingMore(false);
                }
                setDataSource(data)
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        },
        [storedAccessToken, isLoadingMore, dataSource, scheduleCourse]
    );

    useEffect(() => {
        const handleScroll = () => {
            if (!scrollableRef) return;
            const { scrollHeight, scrollTop, clientHeight } = document.getElementsByClassName("MuiDialogContent-root")[0];
            const isScrolledToBottom = scrollHeight - scrollTop >= clientHeight;
            if (isScrolledToBottom && !isLoading) {
                fetchData()
            }
        };
        const scrollableDiv = document.getElementsByClassName("MuiDialogContent-root")[0];
        if (!scrollableDiv) return;
        scrollableDiv.addEventListener("scroll", handleScroll);
        handleScroll()
        return () => {
            scrollableDiv.removeEventListener("scroll", handleScroll);
        };
    }, [fetchData, isLoading]);

    const handleSearch = debounce((inputValue) => {
        // Update items state with the new value
        fetchData( inputValue, true)
    }, 100);

    const handleSearchChange = (e) => {
        const inputValue = e.target.value;
        handleSearch(inputValue);
    };

    function chooseSource(item) {
        let courseList = courseChoose.filter(data => data.id !== item.id);
        if (courseList.length === courseChoose.length) {
            courseList.push(item)
        }
        setCourseChoose(courseList);
    }

    function checkedSource(item) {
        return courseChoose.filter(data => data.id === item.id).length > 0;
    }

    function addCourse() {
        if (courseChoose.length === 0) {
            toast.warning("Bạn cần phải chọn khoá học");
            return;
        }
        let body = {
            courses: {
                create: courseChoose.map(item => {
                    return {
                        learning_plan_id: scheduleId,
                        course_id: {
                            id: item.id
                        }
                    }
                }),
                update: [],
                delete: []
            }
        }

        api.patch(`${process.env.REACT_APP_BASE_URL}/items/learning_plan/${scheduleId}`, body)
            .then(() => {
                toast.success(t('addCourseSuccess'));
                setOpen(false);
                reloadSchedule(scheduleId);
            }).catch(error => {
                toast.error(error.response.data.errors[0].message)
        })
    }

    return <Dialog
        open={open}
        maxWidth="lg"
        scroll="paper"
    >
        <DialogTitle>
            <div className="flex justify-between">
                <p>{t('courseList')}</p>
                <CloseIcon className="cursor-pointer" onClick={() => setOpen(false)}/>
            </div>
        </DialogTitle>
        <DialogContent>
            <div className="w-[1061px] px-[24px]" ref={scrollableRef}>
                {/*Searching*/}
                <div className={`mx-auto w-full bg-[#F5F5F5]`}>
                    <div className="relative flex">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                            <SearchIcon className="text-gray-400"/>
                        </div>
                        <input
                            type="text"
                            placeholder={t("search")}
                            className="bg-white border-solid border-[#D5D5D5] border rounded-lg w-full h-[52px] pl-10 pr-3 text-left"
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                {/*Courses*/}
                <div>
                    <div
                        className={`cursor-pointer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pt-4 pb-14 mx-auto bg-[#F5F5F5]`}>
                        {dataSource?.map((item, index) => (
                            <div
                                key={index}
                                className={`border shadow-md rounded-lg hover:scale-105 duration-300 ${checkedSource(item) ? 'bg-[#F0C528]' : 'bg-white'} `}
                                onClick={() => chooseSource(item)}
                            >
                                <div className="p-4">
                                    <img
                                        key={item.image}
                                        src={`${process.env.REACT_APP_BASE_URL}/assets/${item.image}`}
                                        alt={item?.name}
                                        className='w-full rounded h-[150px] object-cover rounded-t-lg'
                                    />
                                </div>
                                <p className='font-bold flex justify-between px-4 pb-4 text-sm text-left overflow-ellipsis'>
                                    <span>{item?.title}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                {isLoading &&
                <div className="bg-[#F5F5F5] w-full flex justify-center">
                    <span className="loader"></span>
                </div>}
            </div>
        </DialogContent>
        <DialogActions>
            <div className="px-[8px] pb-4">
                <button className="bg-[#F0C528] text-[#000000] border-[0px] rounded-md"
                        onClick={addCourse}>{t('add')} {courseChoose.length > 0 ? courseChoose.length : ''}</button>
            </div>
        </DialogActions>
    </Dialog>
}

export default CourseListDialog;
