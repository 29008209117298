import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import '../App.css';
import "./login.css"
import ImageSlider from "./imageSlider"
import {showToast} from "../common/Toast";
import wsRef from '../WebSocket/wsRef';
import {useTranslation} from "react-i18next";
import {handleClose} from "../WebSocket/LogOutEvent";
import i18next from "i18next";

const LoginForm = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const slides = [
        {url: "/Images/pic1.png", title: "Pic1"},
        {url: "/Images/pic2.png", title: "pic2"},
        {url: "/Images/pic3.png", title: "pic3"}
    ]
    const containerStyles = {
        width: "100%",
        height: "100%",
        margin: "0 auto",
    };

    function refreshTokens() {
        return fetch(`${process.env.REACT_APP_BASE_URL}/auth/refresh`, {
            method: 'POST',
            credentials: 'include'
        });
    }

    function handleLogin() {
        window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/login/google?redirect=${process.env.REACT_APP_SERVER}/`;
        localStorage.setItem('QA', "logIn");
    }

    useEffect(() => {
        const language = ['vi', 'en'];
        const handleConnect = (accessToken) => {
            const url = process.env.REACT_APP_SOCKET + (accessToken ? '?access_token=' + accessToken : '');
            wsRef.current = new WebSocket(url);
        }

        if (localStorage.getItem('QA') !== "logOut") {
            const storedAccessToken = localStorage.getItem('accessToken');
            if (storedAccessToken) {
                navigate("/card");
                handleConnect();
            } else {
                if (localStorage.getItem('QA') === "logIn") {
                    refreshTokens()
                        .then(response => response.json())
                        .then(data => {
                            const accessToken = data?.data?.access_token;
                            if (!accessToken) {
                                localStorage.removeItem("accessToken");
                                localStorage.removeItem("userID");
                                localStorage.removeItem("role");
                                localStorage.setItem("loggedIn", true);
                                localStorage.setItem('QA', "logOut");
                                navigate("/");
                                handleClose();
                                return;
                            }
                            localStorage.setItem('accessToken', accessToken);
                            localStorage.setItem('QA', "active");
                            if (!language.includes(localStorage.getItem('i18nextLng'))) {
                                localStorage.setItem('i18nextLng', language[0]);
                                i18next.changeLanguage(language[0]);
                            }
                            navigate("/home");
                            handleConnect(accessToken);
                        })
                        .catch((error) => {
                            showToast(t("loginFail"), "error");
                            const storedAccessToken = localStorage.getItem('accessToken');

                            if (!storedAccessToken) {
                                localStorage.removeItem("QA");
                            }
                        });
                }
            }
        }
    }, [navigate, t]);

    return (
        <>
            <div style={containerStyles} className="">
                <ImageSlider slides={slides}/>
                <div className="text-2xl" id="loginText">
                    <span className="font-bold">BiNote</span> nơi bạn có thể lưu giữ những kiến thức của mình
                </div>

                <div className="login-page">
                    <div className="form rounded-2xl">
                        <button onClick={handleLogin} className="flex justify-evenly">
                            <img src="/Images/googleIcon.svg" alt="Google icon"/>
                            <div>Đăng nhập với Google</div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default LoginForm;



